import Color from '../../utils/color';

class RarityModel {
    private readonly _name: string;
    private readonly _color: Color;

    constructor(name: string, color: Color) {
        this._name = name;
        this._color = color;
    }

    // Getters for the name and color properties
    get name(): string {
        return this._name;
    }

    get color(): Color {
        return this._color;
    }

    // Static method to create an instance from JSON
    static fromJson(json: {
        name: string;
        color: { r: number; g: number; b: number };
    }): RarityModel {
        const { r, g, b } = json.color;
        const color = new Color(r, g, b);
        return new RarityModel(json.name, color);
    }
}

export default RarityModel;
