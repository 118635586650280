import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import ValueContainer from '../../../utils/value_container';

import { secure_preferences_keys } from '../../../utils/constants';
import TelegramComponent from '../telegram/telegram_component';
import AuthenticationApi from '../../../apis/authentication_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';

const {
    SECURE_PREFERENCES_KEY_COINS,
    SECURE_PREFERENCES_KEY_GEMS,
    SECURE_PREFERENCES_KEY_USERNAME,
    SECURE_PREFERENCES_KEY_TELEGRAM_ID,
    SECURE_PREFERENCES_KEY_TOKEN,
    SECURE_PREFERENCES_KEY_E_RADIUS,
    SECURE_PREFERENCES_KEY_TOTAL_STEPS,
    SECURE_PREFERENCES_KEY_TOTAL_PURCHASED_OFFERS,
    // SECURE_PREFERENCES_KEY_PHONE_NUMBER,
    SECURE_PREFERENCES_KEY_EXPERIENCE,
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL,
    SECURE_PREFERENCES_KEY_EXPERIENCE_UNTIL_NEXT_LEVEL,
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL_EXPERIENCE,
    SECURE_PREFERENCES_KEY_USER_TYPE,
} = secure_preferences_keys;

class AuthenticationComponent extends Component {
    private _authToken?: string;

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([TelegramComponent]);

        const telegramComponent = await this.getComponent(
            TelegramComponent
        ).then((component) => component as TelegramComponent);

        this._authToken = telegramComponent.authToken!;
        ValueContainer.token = this._authToken;

        const authResponse = await AuthenticationApi.testAuth();

        if (!authResponse.isSuccess) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Authentication failed'
                ),
            ]; // TODO: tr()
        }

        await this.saveValues(authResponse.response);

        return [];
    }

    get name(): string {
        return 'Authentication Component';
    }

    onPause(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onResume(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onUnload(): Promise<void> {
        return Promise.resolve(undefined);
    }

    update(): void {}

    get type(): Function {
        return AuthenticationComponent;
    }

    private async saveValues(json: Record<string, any>): Promise<void> {
        const username: string = json[SECURE_PREFERENCES_KEY_USERNAME];
        // const phoneNumber: string | undefined = json[SECURE_PREFERENCES_KEY_PHONE_NUMBER];
        const telegramId: string = json[SECURE_PREFERENCES_KEY_TELEGRAM_ID];
        const coins: number = json[SECURE_PREFERENCES_KEY_COINS] ?? 0;
        const gems: number = json[SECURE_PREFERENCES_KEY_GEMS] ?? 0;
        const token: string = json[SECURE_PREFERENCES_KEY_TOKEN];
        const expectedRadius: number =
            json[SECURE_PREFERENCES_KEY_E_RADIUS] ?? 0;
        const totalSteps: number =
            json[SECURE_PREFERENCES_KEY_TOTAL_STEPS] ?? 0;
        const totalPurchasedOffers: number =
            json[SECURE_PREFERENCES_KEY_TOTAL_PURCHASED_OFFERS] ?? 0;
        const experience: number =
            (json[SECURE_PREFERENCES_KEY_EXPERIENCE] ?? 0) * 1.0;
        const currentLevel: number =
            json[SECURE_PREFERENCES_KEY_CURRENT_LEVEL] ?? 0;
        const experienceUntilNextLevel: number =
            (json[SECURE_PREFERENCES_KEY_EXPERIENCE_UNTIL_NEXT_LEVEL] ?? 0) *
            1.0;
        const currentLevelExperience: number =
            (json[SECURE_PREFERENCES_KEY_CURRENT_LEVEL_EXPERIENCE] ?? 0) * 1.0;
        const userType: number = json[SECURE_PREFERENCES_KEY_USER_TYPE] ?? 0;

        // Store values in SecureStorage and update ValueContainer
        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.TOKEN, token);
        ValueContainer.token = token;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.USERNAME, username);
        ValueContainer.username = username;

        ValueContainer.telegramId = telegramId;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.PHONE_NUMBER, phoneNumber ?? "");
        // ValueContainer.phoneNumber = phoneNumber ?? null;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.COINS, coins.toString());
        ValueContainer.coins = coins;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.GEMS, gems.toString());
        ValueContainer.gems = gems;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.E_RADIUS, expectedRadius.toString());
        ValueContainer.expectedRadius = expectedRadius;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.TOTAL_STEPS, totalSteps.toString());
        ValueContainer.totalSteps = totalSteps;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.TOTAL_PURCHASED_OFFERS, totalPurchasedOffers.toString());
        ValueContainer.totalPurchasedOffers = totalPurchasedOffers;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.EXPERIENCE, experience.toString());
        ValueContainer.experience = experience;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.CURRENT_LEVEL, currentLevel.toString());
        ValueContainer.currentLevel = currentLevel;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.EXPERIENCE_UNTIL_NEXT_LEVEL, experienceUntilNextLevel.toString());
        ValueContainer.experienceUntilNextLevel = experienceUntilNextLevel;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.CURRENT_LEVEL_EXPERIENCE, currentLevelExperience.toString());
        ValueContainer.currentLevelExperience = currentLevelExperience;

        // await SecureStorage.storage.setItem(SECURE_PREFERENCES_KEYS.USER_TYPE, userType.toString());
        ValueContainer.userType = userType;
    }
}

export default AuthenticationComponent;
