import { useState, useEffect } from 'react';

function useFetchComponent<T>(fetchComponent: () => Promise<T>): T | undefined {
    const [component, setComponent] = useState<T | undefined>(undefined);

    useEffect(() => {
        fetchComponent().then(setComponent).catch(console.error);
    }, [fetchComponent]);

    return component;
}

export default useFetchComponent;
