import { local_storage_keys } from '../utils/constants';
import LocaleApi from '../apis/locale_api/locale_api';
import CacheUtils from '../utils/cache_utils';

class LocaleService {
    private static _instance: LocaleService;

    static get instance(): LocaleService {
        return LocaleService._instance;
    }

    private readonly _localeName: string;

    private _locale: Map<string, string> | undefined;

    translate(key: string): string {
        if (!this._locale) {
            throw new Error('Locale not initialized');
        }

        const translation = this._locale.get(key);

        if (!translation) {
            console.warn(`Translation not found for key: ${key}`);
            return key;
        }

        return translation;
    }

    constructor(locale: string) {
        LocaleService._instance = this;
        this._localeName = locale;
    }

    async initialize(): Promise<void> {
        try {
            const [updateTime, content] = await LocaleApi.get(
                this._localeName,
                this._cachedLocaleUpdateTime
            );

            if (updateTime === 0) {
                console.log('No update');
                this._locale = this._cachedLocale!;
                return;
            } else {
                console.log('Update found');
            }

            const locale = new Map<string, string>(
                Object.entries(JSON.parse(content))
            );

            this._locale = locale;
            this._saveLocaleToCache(locale, updateTime);
        } catch (error) {
            console.error('Failed to initialize locale service', error);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            await this.initialize();
        }
    }

    private _localTranslationExists(): boolean {
        return (
            CacheUtils.readStringFromCache(
                local_storage_keys.LOCAL_STORAGE_KEY_CACHED_LOCALE_UPDATE_TIME
            ) !== undefined
        );
    }

    private _saveLocaleToCache(
        locale: Map<string, string>,
        updateTime: number
    ): void {
        CacheUtils.saveMapToCache(
            local_storage_keys.LOCAL_STORAGE_KEY_CACHED_LOCALE,
            locale
        );
        CacheUtils.saveStringToCache(
            local_storage_keys.LOCAL_STORAGE_KEY_CACHED_LOCALE_UPDATE_TIME,
            updateTime.toString()
        );
    }

    private get _cachedLocaleUpdateTime(): number | undefined {
        const updateTime = CacheUtils.readStringFromCache(
            local_storage_keys.LOCAL_STORAGE_KEY_CACHED_LOCALE_UPDATE_TIME
        );
        return updateTime ? parseInt(updateTime) : undefined;
    }

    private get _cachedLocale(): Map<string, string> | undefined {
        return CacheUtils.readMapFromCache(
            local_storage_keys.LOCAL_STORAGE_KEY_CACHED_LOCALE
        );
    }
}

export default LocaleService;
