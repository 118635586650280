import Images from '../../component_system/components/preload/images';

export enum CurrencyType {
    Coin,
    Gem,
}

type CurrencyIconProps = {
    type: CurrencyType;
    size?: number;
};

const CurrencyIcon = ({ type, size = 32 }: CurrencyIconProps) => {
    return (
        <img
            src={
                type === CurrencyType.Coin
                    ? Images.Icons.DiggersGoCoin
                    : Images.Icons.Star
            }
            alt={type === CurrencyType.Coin ? 'Coin' : 'Gem'}
            style={{ width: size, height: size }}
        />
    );
};

export default CurrencyIcon;
