class Color {
    r: number;
    g: number;
    b: number;
    a: number;

    constructor(r: number, g: number, b: number, a: number = 1) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    static fromJson(json: { [key: string]: number }): Color {
        return new Color(
            json['R'] ?? json['r'],
            json['G'] ?? json['g'],
            json['B'] ?? json['b'],
            json['A'] ?? json['a'] ?? 1
        );
    }
}

export default Color;
