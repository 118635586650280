// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: rgb(0, 72, 168);
  --primary-color-triplet: 0, 72, 168;

  --secondary-color: rgb(11, 30, 81);
  --secondary-color-triplet: 11, 30, 81;

  --tertiary-color: rgb(66, 133, 244);
  --tertiary-color-triplet: 66, 133, 244;

  --gold-color: rgb(251, 229, 53);
  --gold-color-triplet: 251, 229, 53;

  --white-color: rgb(255, 255, 255);
  --white-color-triplet: 255, 255, 255;
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  /* Preserve scrolling behavior */
  overflow-y: auto;
}

button {
  font-family: 'Luckiest Guy', cursive;
}

body {
  margin: 0;
  font-family: 'Luckiest Guy', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;EAChC,mCAAmC;;EAEnC,kCAAkC;EAClC,qCAAqC;;EAErC,mCAAmC;EACnC,sCAAsC;;EAEtC,+BAA+B;EAC/B,kCAAkC;;EAElC,iCAAiC;EACjC,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;EACT,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');\n\n:root {\n  --primary-color: rgb(0, 72, 168);\n  --primary-color-triplet: 0, 72, 168;\n\n  --secondary-color: rgb(11, 30, 81);\n  --secondary-color-triplet: 11, 30, 81;\n\n  --tertiary-color: rgb(66, 133, 244);\n  --tertiary-color-triplet: 66, 133, 244;\n\n  --gold-color: rgb(251, 229, 53);\n  --gold-color-triplet: 251, 229, 53;\n\n  --white-color: rgb(255, 255, 255);\n  --white-color-triplet: 255, 255, 255;\n}\n\nhtml, body, #root {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  overscroll-behavior: none;\n}\n\n#root {\n  /* Preserve scrolling behavior */\n  overflow-y: auto;\n}\n\nbutton {\n  font-family: 'Luckiest Guy', cursive;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Luckiest Guy', cursive;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
