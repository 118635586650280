import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import FriendComponent from '../friend/friend_component';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import AuthenticationComponent from '../authentication/authentication_component';
import Friend from '../../../apis/models/friend/friend';
import FriendInfo from '../../../apis/models/friend/friend_info';
import UnityComponent from '../unity/unity_component';
import SettingsComponent from '../settings/settings_component';
import { MethodHandler } from '../signalr/signalr_helper';
import FriendApi from '../../../apis/friend_api/friend_api';
import Pet from '../../../apis/models/friend/pet';
import ValueContainer from '../../../utils/value_container';
import CustomizationComponent from '../customization/customization_component';

class MultiplayerComponent extends Component {
    private _renderedFriends: string[] = [];
    private _renderedPets: string[] = [];

    private _friendComponent: FriendComponent | undefined;
    private _signalRComponent: SignalRComponent | undefined;
    private _unityComponent: UnityComponent | undefined;
    private _settingsComponent: SettingsComponent | undefined;
    private _customizationComponent: CustomizationComponent | undefined;

    get type(): Function {
        return MultiplayerComponent;
    }
    get name(): string {
        return 'Multiplayer Component';
    }
    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([
            AuthenticationComponent,
            FriendComponent,
            SignalRComponent,
            UnityComponent,
            SettingsComponent,
            CustomizationComponent,
        ]);
        this._friendComponent = await ContainerHelper.getFriendComponent();
        this._signalRComponent = await ContainerHelper.getSignalRComponent();
        this._unityComponent = await ContainerHelper.getUnityComponent();
        this._settingsComponent = await ContainerHelper.getSettingsComponent();
        this._customizationComponent =
            await ContainerHelper.getCustomizationComponent();

        this._signalRComponent.registerMethodHandler(
            'MultiplayerCharacterInterpolation',
            this._multiplayerCharacterInterpolationHandler
        );

        this._signalRComponent.registerMethodHandler(
            'MultiplayerPetsInterpolation',
            this._multiplayerPetsInterpolationHandler
        );

        this._friendComponent.addSubscriber(this._friendsSubscriber.bind(this));
        await this._friendsSubscriber();

        return [];
    }
    async onUnload(): Promise<void> {
        this._signalRComponent?.unregisterMethodHandler(
            'MultiplayerCharacterInterpolation',
            this._multiplayerCharacterInterpolationHandler.bind(this)
        );

        this._signalRComponent?.unregisterMethodHandler(
            'MultiplayerPetsInterpolation',
            this._multiplayerPetsInterpolationHandler.bind(this)
        );

        this._friendComponent?.removeSubscriber(
            this._friendsSubscriber.bind(this)
        );
    }
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {}

    private async _friendsSubscriber() {
        const offlineFriends = this._friendComponent!.offlineFriends;
        const onlineFriends = this._friendComponent!.onlineFriends;

        const friendInfos: FriendInfo[] = await FriendApi.getFriendsInfo(
            onlineFriends.map((friend) => friend.phoneNumber!)
        );

        const friendUsernames: string[] = onlineFriends
            .map((friend) => friend.username!)
            .concat(offlineFriends.map((friend) => friend.username!));

        this._customizationComponent!.updatePets(
            friendUsernames,
            friendInfos.filter((fi) => fi.shouldRender)
        );

        for (let i = 0; i < onlineFriends.length; i++) {
            const friend: Friend = onlineFriends[i];

            if (this._renderedFriends.includes(friend.username!)) {
                continue;
            }

            if (!friendInfos.some((fi) => fi.username === friend.username)) {
                continue;
            }

            const friendInfo: FriendInfo = friendInfos.find(
                (fi) => fi.username === friend.username
            )!;

            if (!friendInfo.shouldRender) {
                continue;
            }

            this._renderFriend(friend, friendInfo);
        }

        const onlineFriendUsernames: string[] = onlineFriends.map(
            (friend) => friend.username!
        );
        const renderedFriendsCopy: string[] = this._renderedFriends.slice();

        for (let i = 0; i < renderedFriendsCopy.length; i++) {
            const renderedFriend: string = renderedFriendsCopy[i];
            if (!onlineFriendUsernames.includes(renderedFriend)) {
                this._unityComponent?.destroyGameObject(
                    renderedFriend + 'Character'
                );
                this._renderedFriends = this._renderedFriends.filter(
                    (friend) => friend !== renderedFriend
                );
            }
        }
    }

    private _multiplayerCharacterInterpolationHandler: MethodHandler = async (
        ...args: any[]
    ) => {
        const username: string = args[0];
        const phoneNumber: string = args[1];
        const lat0: number = args[2];
        const lon0: number = args[3];
        const lat1: number = args[4];
        const lon1: number = args[5];
        const reachTime: Date = new Date(args[6]);

        if (this._renderedFriends.includes(username)) {
            this._unityComponent?.postInterpolationDestinationWithReachTime(
                username + 'Character',
                lat1,
                lon1,
                reachTime,
                {
                    watchoutMode: true,
                    watchoutRadius:
                        this._settingsComponent!.getDoubleFromClientSettings(
                            'WatchoutRadius',
                            0
                        ),
                }
            );
        } else {
            this._renderedFriends.push(username);
            const friendInfo: FriendInfo | undefined =
                await FriendApi.getFriendInfo(phoneNumber).catch(
                    () => undefined
                );
            if (!friendInfo) {
                this._renderedFriends = this._renderedFriends.filter(
                    (friend) => friend !== username
                );
                return;
            }

            this._renderFriend0(
                username,
                lat0,
                lon0,
                lat1,
                lon1,
                reachTime,
                friendInfo
            );
        }
    };

    private _multiplayerPetsInterpolationHandler: MethodHandler = async (
        ...args: any[]
    ) => {
        const username: string = args[0];
        const phoneNumber: string = args[1];
        const jsonStr: string = args[2];
        const payload: any = JSON.parse(jsonStr);

        for (let i = 0; i < payload.length; i++) {
            const petId: string = payload[i].PetId;

            if (!this._renderedPets.includes(petId)) {
                continue;
            }

            const lat0: number = payload[i].Latitude0;
            const lon0: number = payload[i].Longitude0;
            const lat1: number = payload[i].Latitude1;
            const lon1: number = payload[i].Longitude1;
            const reachTime: Date = new Date(
                payload[i].TargetPositionEstimatedArrivalTime
            );

            this._unityComponent?.postInterpolationDestinationWithReachTime(
                petId + 'Pet',
                lat1,
                lon1,
                reachTime,
                {
                    watchoutMode: true,
                    watchoutRadius:
                        this._settingsComponent!.getDoubleFromClientSettings(
                            'WatchoutRadius',
                            0
                        ),
                }
            );
        }
    };

    private _renderFriend(friend: Friend, friendInfo: FriendInfo) {
        const latitude: number = friendInfo.latitude;
        const longitude: number = friendInfo.longitude;

        this._unityComponent!.postCharacterCreation(
            friend.username!,
            latitude,
            longitude,
            friendInfo.isMale,
            friendInfo.characterIndex
        );

        this._unityComponent?.postInterpolationDestinationWithReachTime(
            friend.username! + 'Character',
            latitude,
            longitude,
            new Date(Date.now() + 500),
            {
                watchoutMode: true,
                watchoutRadius:
                    this._settingsComponent!.getDoubleFromClientSettings(
                        'WatchoutRadius',
                        0
                    ),
            }
        );

        this._renderedFriends.push(friend.username!);
    }

    // Does not add to rendered friends
    private _renderFriend0(
        username: string,
        latitude: number,
        longitude: number,
        latitude0: number,
        longitude0: number,
        reachTime: Date,
        friendInfo: FriendInfo
    ) {
        this._unityComponent!.postCharacterCreation(
            username,
            latitude,
            longitude,
            friendInfo.isMale,
            friendInfo.characterIndex
        );

        this._unityComponent?.postInterpolationDestinationWithReachTime(
            username + 'Character',
            latitude0,
            longitude0,
            reachTime,
            {
                watchoutMode: true,
                watchoutRadius:
                    this._settingsComponent!.getDoubleFromClientSettings(
                        'WatchoutRadius',
                        0
                    ),
            }
        );
    }

    private async _spawnPet(pet: Pet) {
        await this._unityComponent!.createPet({
            gameObjectNamePrefix: pet.petId,
            addressableAssetName: pet.petClass,
            displayName: pet.name,
            latitude: pet.position.latitude,
            longitude: pet.position.longitude,
        });

        if (this._renderedFriends.includes(pet.ownerUsername)) {
            this._unityComponent?.postInterpolationTargetDestinationWithReachTime(
                pet.petId + 'Pet',
                pet.ownerUsername + 'Character',
                new Date(Date.now() + 500),
                {
                    watchoutMode: true,
                    watchoutRadius:
                        this._settingsComponent!.getDoubleFromClientSettings(
                            'WatchoutRadius',
                            0
                        ),
                }
            );
            return;
        } else if (pet.ownerUsername === ValueContainer.username) {
            this._unityComponent?.postInterpolationTargetDestinationWithReachTime(
                pet.petId + 'Pet',
                'Character',
                new Date(Date.now() + 500),
                {
                    watchoutMode: true,
                    watchoutRadius:
                        this._settingsComponent!.getDoubleFromClientSettings(
                            'WatchoutRadius',
                            0
                        ),
                }
            );
            return;
        }

        this._unityComponent?.postInterpolationDestinationWithReachTime(
            pet.petId + 'Pet',
            pet.position.latitude,
            pet.position.longitude,
            new Date(Date.now() + 500),
            {
                watchoutMode: true,
                watchoutRadius:
                    this._settingsComponent!.getDoubleFromClientSettings(
                        'WatchoutRadius',
                        0
                    ),
            }
        );
    }

    private _destroyPet(petId: string) {
        this._unityComponent?.destroyGameObject(petId + 'Pet');
    }
}

export default MultiplayerComponent;
