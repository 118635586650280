// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_avatar_user-avatar__60uV2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/user_avatar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;IACf,gBAAgB;AACpB","sourcesContent":[".user-avatar {\r\n  width: 50px;\r\n  height: 50px;\r\n  border-radius: 50%;\r\n  object-fit: cover;\r\n    overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-avatar": `user_avatar_user-avatar__60uV2`
};
export default ___CSS_LOADER_EXPORT___;
