import AcquiredOffer from './acquired_offer';
import StandardHttpAPI from '../standard_http_api';
import { constants } from '../../utils/constants';
import ValueContainer from '../../utils/value_container';

const API_URL = constants.API_URL;

class OfferApi {
    static async getAcquiredOffers(): Promise<AcquiredOffer[] | undefined> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/acquiredOffers`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            if (response.responseCode === 404) {
                return [];
            }

            return undefined;
        }

        return response.response.map((acquiredOffer: any) =>
            AcquiredOffer.fromJsonLowerCamelCase(acquiredOffer)
        );
    }
}

export default OfferApi;
