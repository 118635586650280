import StandardHttpResponse from './models/standard_http_response';
import StandardHttpAPI from './standard_http_api';

import { constants } from '../utils/constants';
import ValueContainer from '../utils/value_container';

const API_URL = constants.API_URL;

class AuthenticationApi {
    static async testAuth(): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}mobileUser/testAuth`,
            body: {},
            bearer: ValueContainer.token,
        });
    }
}

export default AuthenticationApi;
