import React, { useCallback, useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import ComponentContainer from '../../component_system/component_container/component_container';
import UnityComponent from '../../component_system/components/unity/unity_component';
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';
import styles from './unity_page.module.css';

const UnityPage: React.FC = () => {
    const {
        unityProvider,
        isLoaded,
        addEventListener,
        removeEventListener,
        sendMessage,
    } = useUnityContext({
        loaderUrl: '/UnityLibrary/Build/UnityLibrary.loader.js',
        dataUrl: '/UnityLibrary/Build/UnityLibrary.data.br',
        frameworkUrl: '/UnityLibrary/Build/UnityLibrary.framework.js.br',
        codeUrl: '/UnityLibrary/Build/UnityLibrary.wasm.br',
        streamingAssetsUrl: '/UnityLibrary/StreamingAssets',
    });

    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );

    useEffect(
        function () {
            // A function which will update the device pixel ratio of the Unity
            // Application to match the device pixel ratio of the browser.
            const updateDevicePixelRatio = function () {
                setDevicePixelRatio(window.devicePixelRatio);
            };
            // A media matcher which watches for changes in the device pixel ratio.
            const mediaMatcher = window.matchMedia(
                `screen and (resolution: ${devicePixelRatio}dppx)`
            );
            // Adding an event listener to the media matcher which will update the
            // device pixel ratio of the Unity Application when the device pixel
            // ratio changes.
            mediaMatcher.addEventListener('change', updateDevicePixelRatio);
            return function () {
                // Removing the event listener when the component unmounts.
                mediaMatcher.removeEventListener(
                    'change',
                    updateDevicePixelRatio
                );
            };
        },
        [devicePixelRatio]
    );

    const handleReactMessage = useCallback(
        (...parameters: ReactUnityEventParameter[]) => {
            const unityComponent = ComponentContainer.instance!.forceGet(
                UnityComponent
            ) as UnityComponent;
            console.log('UnityPage received message:', parameters);
            const message = parameters[0]?.valueOf() as string;
            unityComponent.onReactMessage(message);
        },
        []
    );

    useEffect(() => {
        const unityComponent = ComponentContainer.instance!.forceGet(
            UnityComponent
        ) as UnityComponent;
        if (!unityComponent) {
            return;
        }

        unityComponent.sendMessage = sendMessage;
    }, [sendMessage]);

    useEffect(() => {
        if (isLoaded) {
            const unityComponent = ComponentContainer.instance!.forceGet(
                UnityComponent
            ) as UnityComponent;
            unityComponent.unityLoadCompleter.resolve();
        }
    }, [isLoaded]);

    useEffect(() => {
        addEventListener('PostReactMessage', handleReactMessage);

        return () => {
            removeEventListener('PostReactMessage', handleReactMessage);
        };
    }, [addEventListener, removeEventListener, handleReactMessage]);

    return (
        <Unity
            devicePixelRatio={devicePixelRatio}
            className={styles.unityPlayer}
            unityProvider={unityProvider}
        />
    );
};

export default UnityPage;
