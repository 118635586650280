// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quests_page_questsPage__PpeQL {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.quests_page_appBar__VZ1Dk {
    position: sticky;
    top: 0;
    background-color: #4285f4; /* Replace with your AppColor.Primary.color */
    z-index: 1;
}

.quests_page_appBarContent__9d\\+ou {
    display: flex;
    align-items: center;
    padding: 14px;
}

.quests_page_backButton__5fN8G {
    background: none;
    border: none;
}

.quests_page_appBarTitle__8jAAZ {
    margin-left: 14px;
    color: white;
    font-size: 24px;
}

.quests_page_contentArea__iDVjZ {
    flex-grow: 1;
    overflow-y: auto;
    /*background-image: url('images/static_map.png');*/ /* If you have a background image */
    background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/ui/pages/quests_page.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,yBAAyB,EAAE,6CAA6C;IACxE,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,kDAAkD,EAAE,mCAAmC;IACvF,sBAAsB;AAC1B","sourcesContent":[".questsPage {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100vh;\r\n}\r\n\r\n.appBar {\r\n    position: sticky;\r\n    top: 0;\r\n    background-color: #4285f4; /* Replace with your AppColor.Primary.color */\r\n    z-index: 1;\r\n}\r\n\r\n.appBarContent {\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 14px;\r\n}\r\n\r\n.backButton {\r\n    background: none;\r\n    border: none;\r\n}\r\n\r\n.appBarTitle {\r\n    margin-left: 14px;\r\n    color: white;\r\n    font-size: 24px;\r\n}\r\n\r\n.contentArea {\r\n    flex-grow: 1;\r\n    overflow-y: auto;\r\n    /*background-image: url('images/static_map.png');*/ /* If you have a background image */\r\n    background-size: cover;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questsPage": `quests_page_questsPage__PpeQL`,
	"appBar": `quests_page_appBar__VZ1Dk`,
	"appBarContent": `quests_page_appBarContent__9d+ou`,
	"backButton": `quests_page_backButton__5fN8G`,
	"appBarTitle": `quests_page_appBarTitle__8jAAZ`,
	"contentArea": `quests_page_contentArea__iDVjZ`
};
export default ___CSS_LOADER_EXPORT___;
