import ObservableValue from './observable/observable_value';

class ValueContainer {
    private static _coins = new ObservableValue<number>();
    static get coins(): number {
        return this._coins.value;
    }
    static set coins(value: number) {
        this._coins.value = value;
    }
    static get coinsObservable(): ObservableValue<number> {
        return this._coins;
    }
    static get coinsNullable(): number | undefined {
        return this._coins.nullableValue;
    }

    private static _gems = new ObservableValue<number>();
    static get gems(): number {
        return this._gems.value;
    }
    static set gems(value: number) {
        this._gems.value = value;
    }
    static get gemsObservable(): ObservableValue<number> {
        return this._gems;
    }
    static get gemsNullable(): number | undefined {
        return this._gems.nullableValue;
    }

    private static _expectedRadius = new ObservableValue<number>();
    static get expectedRadius(): number {
        return this._expectedRadius.value;
    }
    static set expectedRadius(value: number) {
        this._expectedRadius.value = value;
    }
    static get expectedRadiusObservable(): ObservableValue<number> {
        return this._expectedRadius;
    }

    private static _username = new ObservableValue<string>();
    static get username(): string {
        return this._username.value;
    }
    static set username(value: string) {
        this._username.value = value;
    }
    static get usernameObservable(): ObservableValue<string> {
        return this._username;
    }
    static get usernameNullable(): string | undefined {
        return this._username.nullableValue;
    }

    private static _telegramId = new ObservableValue<string>();
    static get telegramId(): string {
        return this._telegramId.value;
    }
    static set telegramId(value: string) {
        this._telegramId.value = value;
    }
    static get telegramIdObservable(): ObservableValue<string> {
        return this._telegramId;
    }

    // private static _phoneNumber = new ObservableValue<string | null>();
    // static get phoneNumber(): string | null | undefined { return this._phoneNumber.nullableValue; }
    // static set phoneNumber(value: string | null | undefined) { this._phoneNumber.value = value; }
    // static get phoneNumberObservable(): ObservableValue<string | null> { return this._phoneNumber; }

    private static _token = new ObservableValue<string>();
    static get token(): string {
        return this._token.value;
    }
    static set token(value: string) {
        this._token.value = value;
    }
    static get tokenObservable(): ObservableValue<string> {
        return this._token;
    }

    private static _totalSteps = new ObservableValue<number>();
    static get totalSteps(): number {
        return this._totalSteps.value;
    }
    static set totalSteps(value: number) {
        this._totalSteps.value = value;
    }
    static get totalStepsObservable(): ObservableValue<number> {
        return this._totalSteps;
    }
    static get totalStepsNullable(): number | undefined {
        return this._totalSteps.nullableValue;
    }

    private static _totalPurchasedOffers = new ObservableValue<number>();
    static get totalPurchasedOffers(): number {
        return this._totalPurchasedOffers.value;
    }
    static set totalPurchasedOffers(value: number) {
        this._totalPurchasedOffers.value = value;
    }
    static get totalPurchasedOffersObservable(): ObservableValue<number> {
        return this._totalPurchasedOffers;
    }
    static get totalPurchasedOffersNullable(): number | undefined {
        return this._totalPurchasedOffers.nullableValue;
    }

    private static _experience = new ObservableValue<number>();
    static get experience(): number {
        return this._experience.value;
    }
    static set experience(value: number) {
        this._experience.value = value;
    }
    static get experienceObservable(): ObservableValue<number> {
        return this._experience;
    }

    private static _currentLevel = new ObservableValue<number>();
    static get currentLevel(): number {
        return this._currentLevel.value;
    }
    static set currentLevel(value: number) {
        this._currentLevel.value = value;
    }
    static get currentLevelObservable(): ObservableValue<number> {
        return this._currentLevel;
    }
    static get currentLevelNullable(): number | undefined {
        return this._currentLevel.nullableValue;
    }

    private static _experienceUntilNextLevel = new ObservableValue<number>();
    static get experienceUntilNextLevel(): number {
        return this._experienceUntilNextLevel.value;
    }
    static set experienceUntilNextLevel(value: number) {
        this._experienceUntilNextLevel.value = value;
    }
    static get experienceUntilNextLevelObservable(): ObservableValue<number> {
        return this._experienceUntilNextLevel;
    }
    static get experienceUntilNextLevelNullable(): number | undefined {
        return this._experienceUntilNextLevel.nullableValue;
    }

    private static _currentLevelExperience = new ObservableValue<number>();
    static get currentLevelExperience(): number {
        return this._currentLevelExperience.value;
    }
    static set currentLevelExperience(value: number) {
        this._currentLevelExperience.value = value;
    }
    static get currentLevelExperienceObservable(): ObservableValue<number> {
        return this._currentLevelExperience;
    }
    static get currentLevelExperienceNullable(): number | undefined {
        return this._currentLevelExperience.nullableValue;
    }

    private static _isDebug = new ObservableValue<boolean>();
    static get isDebug(): boolean {
        return this._isDebug.value;
    }
    static set isDebug(value: boolean) {
        this._isDebug.value = value;
    }
    static get isDebugObservable(): ObservableValue<boolean> {
        return this._isDebug;
    }

    private static _userType = new ObservableValue<number>();
    static get userType(): number {
        return this._userType.value;
    }
    static set userType(value: number) {
        this._userType.value = value;
    }
    static get userTypeObservable(): ObservableValue<number> {
        return this._userType;
    }

    private static _characterImageBase64 = new ObservableValue<string>();
    static get characterImageBase64(): string {
        return this._characterImageBase64.value;
    }
    static set characterImageBase64(value: string) {
        this._characterImageBase64.value = value;
    }
    static get characterImageBase64Observable(): ObservableValue<string> {
        return this._characterImageBase64;
    }
    static get characterImageBase64Nullable(): string | undefined {
        return this._characterImageBase64.nullableValue;
    }

    private static _totalFriendsCount = new ObservableValue<number>();
    static get totalFriendsCount(): number {
        return this._totalFriendsCount.value;
    }
    static set totalFriendsCount(value: number) {
        this._totalFriendsCount.value = value;
    }
    static get totalFriendsCountObservable(): ObservableValue<number> {
        return this._totalFriendsCount;
    }
    static get totalFriendsCountNullable(): number | undefined {
        return this._totalFriendsCount.nullableValue;
    }
}

export default ValueContainer;
