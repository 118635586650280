class Stopwatch {
    private startTime: number | null = null;
    private endTime: number | null = null;
    private running = false;

    // Starts the stopwatch
    start(): void {
        if (!this.running) {
            this.startTime = Date.now();
            this.endTime = null;
            this.running = true;
        }
    }

    // Stops the stopwatch
    stop(): void {
        if (this.running) {
            this.endTime = Date.now();
            this.running = false;
        }
    }

    // Resets the stopwatch
    reset(): void {
        this.startTime = null;
        this.endTime = null;
        this.running = false;
    }

    // Gets the elapsed time in milliseconds
    get elapsed(): number {
        if (this.startTime === null) {
            return 0;
        }
        if (this.running) {
            return Date.now() - this.startTime;
        } else if (this.endTime !== null) {
            return this.endTime - this.startTime;
        }
        return 0;
    }

    // Checks if the stopwatch is currently running
    isRunning(): boolean {
        return this.running;
    }
}

export default Stopwatch;