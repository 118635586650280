class Map_bubble {
    private readonly _latitude: number;
    private readonly _longitude: number;
    private readonly _id: number;
    private readonly _metadata?: { [key: string]: any };

    get latitude(): number {
        return this._latitude;
    }

    get longitude(): number {
        return this._longitude;
    }

    get id(): number {
        return this._id;
    }

    get metadata(): { [key: string]: any } | undefined {
        return this._metadata;
    }

    constructor(params: {
        latitude: number;
        longitude: number;
        id: number;
        metadata?: { [key: string]: any };
    }) {
        this._latitude = params.latitude;
        this._longitude = params.longitude;
        this._id = params.id;

        // Capitalize the first letter of each metadata key
        this._metadata = params.metadata
            ? Object.fromEntries(
                  Object.entries(params.metadata).map(([key, value]) => [
                      this.capitalizeFirstLetter(key),
                      value,
                  ])
              )
            : undefined;
    }

    static fromJson(json: { [key: string]: any }): Map_bubble {
        return new Map_bubble({
            latitude: json['Latitude'],
            longitude: json['Longitude'],
            id: json['Id'],
            metadata: json['Metadata'],
        });
    }

    static fromJsonLowerCamelCase(json: { [key: string]: any }): Map_bubble {
        return new Map_bubble({
            latitude: json['latitude'],
            longitude: json['longitude'],
            id: json['id'],
            metadata: json['metadata'],
        });
    }

    private capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

export default Map_bubble;
