class LoadStatus {
    loaded: boolean;
    dependencyLocked: boolean;

    constructor(loaded: boolean, dependencyLocked: boolean) {
        this.loaded = loaded;
        this.dependencyLocked = dependencyLocked;
    }
}

export default LoadStatus;
