import React from 'react';

const RewardsPage = () => {
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
            }}
        ></div>
    );
};

export default RewardsPage;
