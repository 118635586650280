class UsernameValidationResult {
    isValid: boolean;
    message?: string;

    constructor({ isValid, message }: { isValid: boolean; message?: string }) {
        this.isValid = isValid;
        this.message = message;
    }
}

export default UsernameValidationResult;
