class SignalRResponse {
    errorMessage?: string;
    error?: string;
    response?: any;
    isError?: boolean;

    constructor(responseStr: string) {
        try {
            const resp = JSON.parse(responseStr);
            this.isError = resp['IsError'];

            if (this.isError) {
                this.errorMessage = resp['Message'];
                this.error = resp['Error'];
            } else {
                this.response = resp['Response'];
            }
        } catch (error) {
            console.error('Failed to parse response:', error);
            this.isError = true;
            this.errorMessage = 'Invalid response format';
        }
    }
}

export default SignalRResponse;
