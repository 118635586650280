import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import PlayabilityModel from './playability_model';
import PlayabilityApi from '../../../apis/playability_api/playability_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import AuthenticationComponent from '../authentication/authentication_component';

type PlayabilitySubscriber = () => void;

class PlayabilityComponent extends Component {
    private _playability: PlayabilityModel | undefined = undefined;

    private _subscribers: PlayabilitySubscriber[] = [];

    registerSubscriber(subscriber: PlayabilitySubscriber): void {
        this._subscribers.push(subscriber);
    }

    unregisterSubscriber(subscriber: PlayabilitySubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private _userPlayabilityUpdatedMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        this._playability = PlayabilityModel.fromJson(json);
    }

    get type(): Function {
        return PlayabilityComponent;
    }

    get name(): string {
        return 'Playability Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);

        this._playability = await this._getPlayability();

        if (!this._playability) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'playabilityComponentFailedToLoad'.tr()
                ),
            ];
        }

        await this.setDependencyLocked([SignalRComponent]);

        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'UserPlayabilityUpdated',
            this._userPlayabilityUpdatedMethodHandler.bind(this)
        );

        return [];
    }
    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {}

    private async _getPlayability(): Promise<PlayabilityModel | undefined> {
        const response = await PlayabilityApi.getPlayability();

        if (!response.isSuccess) {
            return undefined;
        }

        return PlayabilityModel.fromJsonLowerCamelCase(response.response);
    }
}

export default PlayabilityComponent;
