class CacheUtils {
    static saveStringToCache(key: string, str: string) {
        localStorage.setItem(key, str);
    }

    static readStringFromCache(key: string): string | undefined {
        return localStorage.getItem(key) ?? undefined;
    }

    static saveObjectToCache(key: string, obj: any) {
        localStorage.setItem(key, JSON.stringify(obj));
    }

    static readObjectFromCache(key: string): any | undefined {
        const json = localStorage.getItem(key);
        if (json) {
            return JSON.parse(json);
        }
        return undefined;
    }

    static saveMapToCache(key: string, map: Map<string, string>) {
        const obj = Object.fromEntries(map); // Convert map to an object
        localStorage.setItem(key, JSON.stringify(obj));
    }

    static readMapFromCache(key: string): Map<string, any> | undefined {
        const json = localStorage.getItem(key);
        if (json) {
            const obj = JSON.parse(json);
            return new Map(Object.entries(obj)); // Convert object to map
        }
        return undefined;
    }
}

export default CacheUtils;
