import Map_bubble from '../models/map_bubble';
import MapProfile from '../models/map_profile';
import MapEvent from '../models/map_event';

class PopulationResponse {
    bubbles: Map_bubble[];
    mapProfiles: MapProfile[];
    mapEvents: MapEvent[];

    constructor(params: {
        bubbles: Map_bubble[];
        mapProfiles: MapProfile[];
        mapEvents: MapEvent[];
    }) {
        this.bubbles = params.bubbles;
        this.mapProfiles = params.mapProfiles;
        this.mapEvents = params.mapEvents;
    }

    static fromJson(json: { [key: string]: any }): PopulationResponse {
        return new PopulationResponse({
            bubbles: (json['Bubbles'] as Array<any>).map((e: any) =>
                Map_bubble.fromJson(e)
            ),
            mapProfiles: (json['MapProfiles'] as Array<any>).map((e: any) =>
                MapProfile.fromJson(e)
            ),
            mapEvents: (json['MapEvents'] as Array<any>).map((e: any) =>
                MapEvent.fromJson(e)
            ),
        });
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): PopulationResponse {
        return new PopulationResponse({
            bubbles: (json['bubbles'] as Array<any>).map((e: any) =>
                Map_bubble.fromJsonLowerCamelCase(e)
            ),
            mapProfiles: (json['mapProfiles'] as Array<any>).map((e: any) =>
                MapProfile.fromJsonLowerCamelCase(e)
            ),
            mapEvents: (json['mapEvents'] as Array<any>).map((e: any) =>
                MapEvent.fromJsonLowerCamelCase(e)
            ),
        });
    }
}

export default PopulationResponse;
