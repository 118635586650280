import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import OfferApi from '../../../apis/offer_api/offer_api';
import AuthenticationComponent from '../authentication/authentication_component';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';

type OfferSubscriber = () => void;

class OfferComponent extends Component {
    private _subscribers: OfferSubscriber[] = [];

    registerSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers.push(subscriber);
    }

    unregisterSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private _acquiredOffers: AcquiredOffer[] | undefined = undefined;

    get unseenOfferCount(): number {
        return (
            this._acquiredOffers?.filter((offer) => !offer.isSeen).length || 0
        );
    }

    private _fetching: boolean = false;
    get fetching(): boolean {
        return this._fetching;
    }

    async markOfferAsSeen(uniqueOfferId: string): Promise<void> {
        // TODO: Implement
    }

    private async _initValues(): Promise<void> {
        const acquiredOffers = await OfferApi.getAcquiredOffers();

        if (!acquiredOffers) {
            return Promise.reject();
        }

        this._acquiredOffers = acquiredOffers;
    }

    get type(): Function {
        return OfferComponent;
    }

    get name(): string {
        return 'Offer Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);

        const initSuccess = await this._initValues()
            .then(() => true)
            .catch(() => false);

        if (!initSuccess) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'offerComponentFailedToLoad'.tr()
                ),
            ];
        }

        await this.setDependencyLocked([SignalRComponent]);

        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'OfferAcquired',
            this._offerAcquiredMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'RemoveAcquiredOffer',
            this._removeAcquiredOfferMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'OfferRedeemed',
            this._offerRedeemedMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        return [];
    }

    private _offerAcquiredMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        const acquiredOffer = AcquiredOffer.fromJson(json);

        this._acquiredOffers!.push(acquiredOffer);
        this._notifySubscribers();
    }

    private _removeAcquiredOfferMethodHandler(...args: any[]): void {
        const uniqueId = args[0];

        this._acquiredOffers = this._acquiredOffers?.filter(
            (offer) => offer.uniqueId !== uniqueId
        );

        this._notifySubscribers();
    }

    private _offerRedeemedMethodHandler(...args: any[]): void {
        const uniqueId = args[0];

        const acquiredOffer = this._acquiredOffers?.find(
            (offer) => offer.uniqueId === uniqueId
        );

        if (acquiredOffer) {
            acquiredOffer.isUsed = true;
        }

        this._notifySubscribers();
    }

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            this.fetch();
        }
    }

    async fetch(): Promise<void> {
        if (this._fetching) {
            return;
        }
        let fetched = false;
        this._fetching = true;

        this._notifySubscribers();

        while (!fetched) {
            fetched = await this._initValues()
                .then(() => true)
                .catch(() => false);

            if (!fetched) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } else {
                this._fetching = false;
                this._notifySubscribers();
            }
        }

        this._fetching = false;
    }

    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {}
}

export default OfferComponent;
