// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/backgrounds/blue_tile.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quest_item_questItem__TNr-M {
  height: 160px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  padding: 18px;
  display: flex;
  flex-direction: column;
  color: white;
}

.quest_item_questTitle__QSuvN {
  font-family: 'Luckiest Guy', cursive;
  font-size: 32px;
  margin: 0;
}

.quest_item_questDescription__u4cB7 {
  font-family: 'Luckiest Guy', cursive;
  font-size: 16px;
  margin: 0;
}

.quest_item_questSpacer__DDAW0 {
  flex-grow: 1;
}

.quest_item_questFooter__FDI7A {
  display: flex;
  align-items: center;
}

.quest_item_questReward__cgNA0 {
  font-family: 'Luckiest Guy', cursive;
  font-size: 24px;
  color: rgb(251, 229, 53);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/ui/components/quest_item.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,yDAAsE;EACtE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,SAAS;AACX;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,wBAAwB;EACxB,2CAA2C;AAC7C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');\r\n\r\n.questItem {\r\n  height: 160px;\r\n  background-image: url('../../assets/images/backgrounds/blue_tile.png');\r\n  background-size: cover;\r\n  padding: 18px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  color: white;\r\n}\r\n\r\n.questTitle {\r\n  font-family: 'Luckiest Guy', cursive;\r\n  font-size: 32px;\r\n  margin: 0;\r\n}\r\n\r\n.questDescription {\r\n  font-family: 'Luckiest Guy', cursive;\r\n  font-size: 16px;\r\n  margin: 0;\r\n}\r\n\r\n.questSpacer {\r\n  flex-grow: 1;\r\n}\r\n\r\n.questFooter {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.questReward {\r\n  font-family: 'Luckiest Guy', cursive;\r\n  font-size: 24px;\r\n  color: rgb(251, 229, 53);\r\n  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questItem": `quest_item_questItem__TNr-M`,
	"questTitle": `quest_item_questTitle__QSuvN`,
	"questDescription": `quest_item_questDescription__u4cB7`,
	"questSpacer": `quest_item_questSpacer__DDAW0`,
	"questFooter": `quest_item_questFooter__FDI7A`,
	"questReward": `quest_item_questReward__cgNA0`
};
export default ___CSS_LOADER_EXPORT___;
