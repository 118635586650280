import Completer from "./completer";

class QueueItem<T> {
    private _completer = new Completer<void>();
    private _value: T;
    private _minQueueDelay: number;
    private _timeoutItem: boolean;

    constructor(value: T, minQueueDelay: number, timeoutItem = false) {
        this._value = value;
        this._minQueueDelay = minQueueDelay;
        this._timeoutItem = timeoutItem;
    }

    static asTimeout<T>(value: T, minQueueDelay: number): QueueItem<T> {
        return new QueueItem(value, minQueueDelay, true);
    }

    get completer(): Completer<void> {
        return this._completer;
    }

    get value(): T {
        return this._value;
    }

    get minQueueDelay(): number {
        return this._minQueueDelay;
    }

    get isTimeoutItem(): boolean {
        return this._timeoutItem;
    }
}

class AutoQueue<T> {
    private _queue: Array<QueueItem<T | null>> = [];
    private readonly _onDequeue: (object: T) => void;
    private readonly _onEnd: () => void;

    private readonly _updateFrequency: number;
    private readonly _timeOutDuration: number;
    private _durationSinceLastDequeue = 0;
    private _enqueuedSinceTimeoutStart = false;
    private _stop = false;

    constructor(
        updateFrequency: number,
        timeOutDuration: number,
        onDequeue: (object: T) => void,
        onEnd: () => void
    ) {
        this._onDequeue = onDequeue;
        this._updateFrequency = updateFrequency;
        this._timeOutDuration = timeOutDuration;
        this._onEnd = onEnd;
    }

    start(): void {
        if (this._queue.length === 0) {
            this._queue.push(QueueItem.asTimeout(null, this._timeOutDuration));
        }
        let lastTimerRun = Date.now();

        this._startLoop();

        setInterval(() => {
            if (this._stop) {
                this._onEnd();
                return;
            }

            const now = Date.now();
            this._durationSinceLastDequeue += now - lastTimerRun;
            lastTimerRun = now;

            if (this._queue.length > 0) {
                const firstItem = this._queue[0];
                if (!firstItem.completer.resolved) {
                    if (
                        this._durationSinceLastDequeue > firstItem.minQueueDelay ||
                        (firstItem.isTimeoutItem && this._enqueuedSinceTimeoutStart)
                    ) {
                        firstItem.completer.resolve();
                        this._durationSinceLastDequeue = 0;
                    }
                }
            }
        }, this._updateFrequency);
    }

    private async _startLoop(): Promise<void> {
        while (true) {
            if (this._queue.length === 0) return;

            const firstItem = this._queue[0];
            const wasTimeout = firstItem.isTimeoutItem;

            if (!wasTimeout) {
                const value = firstItem.value;
                this._onDequeue(value as T);
            }

            await firstItem.completer.promise;
            this._queue.shift();

            if (!wasTimeout && this._queue.length === 0) {
                this._queue.push(QueueItem.asTimeout(null, this._timeOutDuration));
            } else if (wasTimeout && this._queue.length === 0) {
                this._stop = true;
                break;
            }
        }
    }

    enqueue(object: T, minQueueDelay: number): void {
        this._queue.push(new QueueItem(object, minQueueDelay));
        if (this._queue.length > 0) {
            const lastItem = this._queue[this._queue.length - 1];
            if (lastItem.isTimeoutItem) {
                this._enqueuedSinceTimeoutStart = true;
            }
        }
    }
}

export default AutoQueue;