import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRHelper, { MethodHandler } from './signalr_helper';
import ValueContainer from '../../../utils/value_container';
import { constants } from '../../../utils/constants';
import AuthenticationComponent from '../authentication/authentication_component';
import SignalRConnected from './signalr_connected';
import SignalRReady from './signalr_ready';
import SignalRResponse from './signalr_response';
import { HubConnectionState } from '@microsoft/signalr';

const API_URL = constants.API_URL;

type ConnectionStateChangedEventHandler = (connected: SignalRConnected) => void;
type ReadyStateChangedEventHandler = (ready: SignalRReady) => void;

class SignalRComponent extends Component {
    private _signalR!: SignalRHelper;
    private _authenticated = false;
    private _retryingConnection = false;

    private _connectionStateChangedEventHandlers: ConnectionStateChangedEventHandler[] =
        [];
    private _readyStateChangedEventHandlers: ReadyStateChangedEventHandler[] =
        [];

    addConnectionStateChangedEventHandler(
        handler: ConnectionStateChangedEventHandler
    ): void {
        this._connectionStateChangedEventHandlers.push(handler);
    }

    removeConnectionStateChangedEventHandler(
        handler: ConnectionStateChangedEventHandler
    ): void {
        this._connectionStateChangedEventHandlers =
            this._connectionStateChangedEventHandlers.filter(
                (h) => h !== handler
            );
    }

    addReadyStateChangedEventHandler(
        handler: ReadyStateChangedEventHandler
    ): void {
        this._readyStateChangedEventHandlers.push(handler);
    }

    removeReadyStateChangedEventHandler(
        handler: ReadyStateChangedEventHandler
    ): void {
        this._readyStateChangedEventHandlers =
            this._readyStateChangedEventHandlers.filter((h) => h !== handler);
    }

    get type(): Function {
        return SignalRComponent;
    }

    get name(): string {
        return 'SignalR Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);
        try {
            // Initialize SignalR helper
            this._signalR = new SignalRHelper();
            this._signalR.addConnectionCloseHandler(
                this._connectionCloseHandler.bind(this)
            );
            this._signalR.addConnectionOpenHandler(
                this._connectionOpenHandler.bind(this)
            );

            // Attempt to connect and authenticate
            const result = await this._tryConnectAndAuthenticate();
            if (!result) {
                return [
                    new ComponentError(
                        ComponentErrorType.LoadError,
                        'Failed to connect and authenticate with SignalR'
                    ),
                ];
            }

            // Register necessary method handlers
            this.registerMethodHandlers();
            return [];
        } catch (error) {
            console.error('Load error:', error);
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Unexpected error during SignalR load'
                ),
            ];
        }
    }

    async onUnload(): Promise<void> {
        if (this._signalR.connectionState === HubConnectionState.Connected) {
            await this._signalR.disconnect();
        }
    }

    async onPause(): Promise<void> {
        if (this._signalR.connected && this._authenticated) {
            await this._unAuthenticate();
            await this._signalR.disconnect();
            this._authenticated = false;
        }
    }

    async onResume(): Promise<void> {}

    update(sinceLastUpdate: TimeDuration): void {
        if (
            !this.paused &&
            !this.unloaded &&
            this._signalR.connectionState !== HubConnectionState.Connected &&
            !this._retryingConnection
        ) {
            this._authenticated = false;

            // _setSignalRConnectedOverlay(false);

            this._log('Lost connection. Trying to reconnect - U');
            this._startRetryChain();
        }
    }

    private async _tryConnectAndAuthenticate(): Promise<boolean> {
        try {
            if (!this._signalR.connected) await this._signalR.connect();
            this._authenticated = await this._authenticate();

            if (this._authenticated) {
                this._readyStateChangedEventHandlers.forEach((handler) =>
                    handler(SignalRReady.Ready)
                );
            }

            return this._authenticated;
        } catch (error) {
            console.error('Authentication error:', error);
            return false;
        }
    }

    private async _authenticate(): Promise<boolean> {
        const connectionId = this._signalR.connectionId;
        if (!connectionId) {
            console.log('No connection ID available for authentication');
            return false;
        }
        // TODO: move to api class
        const response = await fetch(
            API_URL + `MobileUser/authorize_signalr_connection/${connectionId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + ValueContainer.token,
                },
            }
        );
        if (response.ok) {
            console.log('Authenticated successfully');
            return true;
        } else {
            console.error('Authentication failed:', await response.text());
            return false;
        }
    }

    private async _unAuthenticate(): Promise<boolean> {
        const connectionId = this._signalR.connectionId;
        if (!connectionId) return false;

        // TODO: move to api class
        const response = await fetch(
            API_URL +
                `MobileUser/unauthorize_signalr_connection/${connectionId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${ValueContainer.token}`,
                },
            }
        );
        return response.ok;
    }

    private _connectionCloseHandler(error?: Error): void {
        console.log('Connection closed:', error);
        this._authenticated = false;

        this._connectionStateChangedEventHandlers.forEach((handler) =>
            handler(SignalRConnected.Disconnected)
        );

        this._readyStateChangedEventHandlers.forEach((handler) =>
            handler(SignalRReady.NotReady)
        );
    }

    private async _connectionOpenHandler(): Promise<void> {
        console.log('Connection opened');

        this._connectionStateChangedEventHandlers.forEach((handler) =>
            handler(SignalRConnected.Connected)
        );

        if (this.paused) {
            await this._signalR.disconnect();
        }
    }

    private _startRetryChain(): void {
        this._retryingConnection = true;
        setTimeout(async () => {
            const connected = await this._tryConnectAndAuthenticate();
            if (!connected && !this._retryingConnection) {
                console.log('Retrying connection...');
                this._startRetryChain();
            } else {
                this._retryingConnection = false;
            }
        }, 2000);
    }

    private _log(message: string): void {
        console.log(`[${this.name}] ${message}`);
    }

    registerMethodHandler(method: string, methodHandler: MethodHandler): void {
        this._signalR.registerMethodHandler(method, methodHandler);
    }

    unregisterMethodHandler(
        methodName: string,
        methodHandler: MethodHandler
    ): void {
        this._signalR.unregisterMethodHandler(methodName, methodHandler);
    }

    private registerMethodHandlers(): void {
        // TODO: ActivatedBubbleRemoved

        this._signalR.registerMethodHandler('Log', (...args: any[]) => {
            if (!args || args.length !== 1) return;
        });

        this._signalR.registerMethodHandler('UpdateValue', (...args: any[]) => {
            if (!args || args.length !== 2) return;
            const [key, value] = args;
            switch (key) {
                case 'coins':
                    ValueContainer.coins = value;
                    break;
                case 'gems':
                    ValueContainer.gems = value;
                    break;
                case 'totalSteps':
                    ValueContainer.totalSteps = value;
                    break;
                case 'totalPurchasedOffers':
                    ValueContainer.totalPurchasedOffers = value;
                    break;
                case 'currentLevel':
                    ValueContainer.currentLevel = value;
                    break;
                case 'experience':
                    ValueContainer.experience = value;
                    break;
                case 'experienceUntilNextLevel':
                    ValueContainer.experienceUntilNextLevel = value;
                    break;
                case 'currentLevelExperience':
                    ValueContainer.currentLevelExperience = value;
                    break;
                default:
                    console.log(`Unhandled value update: ${key} = ${value}`);
                    break;
            }
        });
    }

    private _checkForDefaultProblems(): SignalRResponse | undefined {
        if (!this._signalR.connected) {
            this._log('SignalR is not connected');
            return new SignalRResponse(
                JSON.stringify({
                    IsError: true,
                    Error: 'NOT_CONNECTED',
                    Message: 'SignalR is not connected',
                })
            );
        }

        if (!this._authenticated) {
            this._log('User is not authenticated');
            return new SignalRResponse(
                JSON.stringify({
                    IsError: true,
                    Error: 'NOT_AUTHENTICATED',
                    Message: 'User is not authenticated',
                })
            );
        }
    }

    private _toIsoStringWithMillis(date: Date): string {
        const pad = (n: number) => String(n).padStart(2, '0');
        return (
            date.getUTCFullYear() +
            '-' +
            pad(date.getUTCMonth() + 1) +
            '-' +
            pad(date.getUTCDate()) +
            'T' +
            pad(date.getUTCHours()) +
            ':' +
            pad(date.getUTCMinutes()) +
            ':' +
            pad(date.getUTCSeconds()) +
            '.' +
            String((date.getUTCMilliseconds() / 1000).toFixed(3)).slice(2, 5)
            // + 'Z'
        );
    }

    async sendLocation(
        latitude: number,
        longitude: number,
        lastBoot: Date
    ): Promise<SignalRResponse> {
        const defaultProblem = this._checkForDefaultProblems();

        if (defaultProblem) return defaultProblem;

        try {
            const formattedLatitude = parseFloat(latitude.toFixed(8));
            const formattedLongitude = parseFloat(longitude.toFixed(8));
            const response = await this._signalR.invoke<string>(
                'NewLocation',
                formattedLatitude,
                formattedLongitude,
                lastBoot.toISOString().replace('Z', '')
            );
            return new SignalRResponse(response as string);
        } catch (error) {
            this._log('Error executing NewLocation method');
            return new SignalRResponse(
                JSON.stringify({
                    IsError: true,
                    Error: 'EXECUTION_ERROR',
                    Message:
                        'An error occurred during SignalR method execution',
                })
            );
        }
    }

    async sendInterpolation(
        latitude0: number,
        longitude0: number,
        latitude1: number,
        longitude1: number,
        duration: number // Duration in milliseconds
    ): Promise<SignalRResponse> {
        const defaultProblem = this._checkForDefaultProblems();

        if (defaultProblem) return defaultProblem;

        const reachTime = new Date(Date.now() + duration).toISOString();

        try {
            const response = await this._signalR.invoke(
                'StartInterpolationAsync',
                latitude0,
                longitude0,
                latitude1,
                longitude1,
                reachTime
            );
            return new SignalRResponse(response as string);
        } catch (error) {
            this._log('Error executing StartInterpolationAsync method');
            return new SignalRResponse(
                JSON.stringify({
                    IsError: true,
                    Error: 'EXECUTION_ERROR',
                    Message:
                        'An error occurred during SignalR method execution',
                })
            );
        }
    }

    async postUnityMessage(
        gameObject: string,
        methodName: string,
        message: string
    ): Promise<SignalRResponse> {
        const defaultProblem = this._checkForDefaultProblems();

        if (defaultProblem) return defaultProblem;

        try {
            const response = await this._signalR.invoke(
                'UnityMessage',
                gameObject,
                methodName,
                message
            );
            return new SignalRResponse(response as string);
        } catch (error) {
            this._log('Error executing PostUnityMessage method');
            return new SignalRResponse(
                JSON.stringify({
                    IsError: true,
                    Error: 'EXECUTION_ERROR',
                    Message:
                        'An error occurred during SignalR method execution',
                })
            );
        }
    }
}

export default SignalRComponent;
