import Color from '../../utils/color';

class MapEvent {
    id: string;
    latitude: number;
    longitude: number;
    color: Color;
    range: number;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    coverUrl: string;

    constructor(params: {
        id: string;
        latitude: number;
        longitude: number;
        color: Color;
        range: number;
        startDate: Date;
        endDate: Date;
        title: string;
        description: string;
        coverUrl: string;
    }) {
        this.id = params.id;
        this.latitude = params.latitude;
        this.longitude = params.longitude;
        this.color = params.color;
        this.range = params.range;
        this.startDate = params.startDate;
        this.endDate = params.endDate;
        this.title = params.title;
        this.description = params.description;
        this.coverUrl = params.coverUrl;
    }

    static fromJson(json: { [key: string]: any }): MapEvent {
        return new MapEvent({
            id: json['Id'],
            latitude: json['Latitude'],
            longitude: json['Longitude'],
            color: Color.fromJson(json['Color']),
            range:
                typeof json['Range'] === 'number'
                    ? json['Range']
                    : parseFloat(json['Range']),
            startDate: new Date(json['StartDate']),
            endDate: new Date(json['EndDate']),
            title: json['Title'],
            description: json['Description'],
            coverUrl: json['CoverUrl'],
        });
    }

    static fromJsonLowerCamelCase(json: { [key: string]: any }): MapEvent {
        return new MapEvent({
            id: json['id'],
            latitude: json['latitude'],
            longitude: json['longitude'],
            color: Color.fromJson(json['color']),
            range:
                typeof json['range'] === 'number'
                    ? json['range']
                    : parseFloat(json['range']),
            startDate: new Date(json['startDate']),
            endDate: new Date(json['endDate']),
            title: json['title'],
            description: json['description'],
            coverUrl: json['coverUrl'],
        });
    }
}

export default MapEvent;
