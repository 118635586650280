import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import TelegramApi from '../../../apis/telegram_api';
import TelegramValidationResult from './telegram_vaildation_result';
import ComponentErrorType from '../../component_container/enums/component_error_type';

import { component_error_codes } from '../../../utils/constants';
import UsernameValidationResult from './username_validation_result';

class TelegramComponent extends Component {
    private _usernameCreationParameter?: string;
    private _authToken?: string;

    get authToken(): string | undefined {
        return this._authToken;
    }

    set usernameCreationParameter(value: string | undefined) {
        this._usernameCreationParameter = value;
    }

    async load(): Promise<Array<ComponentError>> {
        window.Telegram.WebApp.enableClosingConfirmation();
        window.Telegram.WebApp.disableVerticalSwipes();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.setBackgroundColor('#0048A8');
        window.Telegram.WebApp.setHeaderColor('#0048A8');
        window.Telegram.WebApp.setBottomBarColor('#0048A8');

        const initData = window.Telegram.WebApp.initData;
        const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

        if (!initData) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'telegramInitializationDataNotFound'.tr()
                ),
            ];
        }

        const validationResult = await this.validate(
            initData,
            this._usernameCreationParameter,
            startParam
        );

        if (!validationResult.isValid) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    validationResult.message || 'unknownError'.tr()
                ),
            ];
        }

        const token = validationResult.token;

        if (!token) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'tokenNotFoundInValidationResponse'.tr(),
                    undefined,
                    component_error_codes.TELEGRAM_REGISTRATION_INCOMPLETE
                ),
            ]; // TODO: tr()
        }

        this._authToken = token;

        return [];
    }

    async validate(
        initData: string,
        username?: string,
        referrerTelegramId?: string
    ): Promise<TelegramValidationResult> {
        const response = await TelegramApi.validate(
            initData,
            username,
            referrerTelegramId
        );
        if (response.isSuccess) {
            return new TelegramValidationResult({
                isValid: true,
                token: response.response['token'],
            });
        } else {
            return new TelegramValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            }); // TODO: tr()
        }
    }

    async validateUsername(
        username: string
    ): Promise<UsernameValidationResult> {
        const response = await TelegramApi.validateUsername(username);
        if (response.isSuccess) {
            return new UsernameValidationResult({
                isValid: true,
            });
        } else {
            return new UsernameValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            });
        }
    }

    get name(): string {
        return 'Telegram Component';
    }

    onPause(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onResume(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onUnload(): Promise<void> {
        return Promise.resolve(undefined);
    }

    update(sinceLastUpdate: TimeDuration): void {}

    get type(): Function {
        return TelegramComponent;
    }
}

export default TelegramComponent;
