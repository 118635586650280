import { constants } from '../../utils/constants';

const API_URL = constants.API_URL;

class LocaleApi {
    static async get(
        localeName: string,
        updateTime?: number
    ): Promise<[updateTime: number, content: string]> {
        let url = `${API_URL}locale/${localeName}`;
        if (updateTime) {
            url += `?updateTime=${updateTime}`;
        }
        const response = await fetch(`${url}`);

        if (!response.ok) {
            if (response.status === 400) {
                return [0, '']; // No update
            } else {
                return Promise.reject(response);
            }
        }

        const returnedUpdateTime: number = parseInt(
            response.headers.get('updatetime') ?? '0'
        );

        const content = await response.text();

        return [returnedUpdateTime, content];
    }

    static async getAvailableLocales(): Promise<string[]> {
        const response = await fetch(`${API_URL}locale/availableLocales`);

        if (!response.ok) {
            return Promise.reject(response);
        }

        return await response.json();
    }
}

export default LocaleApi;
