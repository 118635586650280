class Friend {
    private readonly _phoneNumber?: string;
    private readonly _username?: string;

    constructor(phoneNumber?: string, username?: string) {
        this._phoneNumber = phoneNumber;
        this._username = username;
    }

    get phoneNumber(): string | undefined {
        return this._phoneNumber;
    }

    get username(): string | undefined {
        return this._username;
    }

    static fromJsonLowerCamelCase(json: any): Friend {
        return new Friend(json.phoneNumber, json.username);
    }

    static fromJsonUpperCase(json: any): Friend {
        return new Friend(json.PhoneNumber, json.Username);
    }
}

export default Friend;
