import CompanyProfileType from './company_profile_type';
import Color from '../../utils/color';
import CompactJsonOffer from './compact_json_offer';

class CompanyProfile {
    profileType: CompanyProfileType;
    parentProfileId?: string;
    name: string;
    description?: string;
    latitude?: number;
    longitude?: number;
    logoUrl?: string;
    preferredBackgroundColor?: Color;
    offers?: CompactJsonOffer[];
    website?: string;

    constructor(
        profileType: CompanyProfileType,
        name: string,
        offers: CompactJsonOffer[] | null = null,
        parentProfileId?: string,
        description?: string,
        latitude?: number,
        longitude?: number,
        logoUrl?: string,
        website?: string,
        preferredBackgroundColor?: Color
    ) {
        this.profileType = profileType;
        this.name = name;
        this.offers = offers || undefined;
        this.parentProfileId = parentProfileId;
        this.description = description;
        this.latitude = latitude;
        this.longitude = longitude;
        this.logoUrl = logoUrl;
        this.website = website;
        this.preferredBackgroundColor = preferredBackgroundColor;
    }

    static nul(): CompanyProfile {
        return new CompanyProfile(CompanyProfileType.LOCAL, '', null);
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): CompanyProfile {
        return new CompanyProfile(
            CompanyProfileType[
                json[
                    'profileType'
                ].toUpperCase() as keyof typeof CompanyProfileType
            ],
            json['profileName'],
            json['profileOffers']
                ? (json['profileOffers'] as Array<any>).map((e) =>
                      CompactJsonOffer.fromJsonLowerCamelCase(e)
                  )
                : null,
            json['parentProfileId'],
            json['profileDescription'],
            json['profileLatitude'],
            json['profileLongitude'],
            json['profileLogoUrl'],
            json['profileWebsite'],
            json['profilePreferredBackgroundColor']
                ? Color.fromJson(json['profilePreferredBackgroundColor'])
                : undefined
        );
    }

    static fromJson(json: { [key: string]: any }): CompanyProfile {
        return new CompanyProfile(
            CompanyProfileType[
                json[
                    'ProfileType'
                ].toUpperCase() as keyof typeof CompanyProfileType
            ],
            json['ProfileName'],
            json['ProfileOffers']
                ? (json['ProfileOffers'] as Array<any>).map((e) =>
                      CompactJsonOffer.fromJson(e)
                  )
                : null,
            json['ProfileParentId'],
            json['ProfileDescription'],
            json['ProfileLatitude'],
            json['ProfileLongitude'],
            json['ProfileLogoUrl'],
            json['ProfileWebsite'],
            json['ProfilePreferredBackgroundColor']
                ? Color.fromJson(json['ProfilePreferredBackgroundColor'])
                : undefined
        );
    }
}

export default CompanyProfile;
