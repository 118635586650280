import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';
import PopulationResponse from './population_response';

const API_URL = constants.API_URL;

class PopulationApi {
    static async getPopulation(): Promise<PopulationResponse> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}population`,
            bearer: ValueContainer.token,
        });
        return PopulationResponse.fromJsonLowerCamelCase(response.response);
    }
}

export default PopulationApi;
