import StandardHttpResponse from '../models/standard_http_response';
import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';

const API_URL = constants.API_URL;

class PlayabilityApi {
    static async getPlayability(): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.get({
            uri: `${API_URL}playability`,
            bearer: ValueContainer.token,
        });
    }
}

export default PlayabilityApi;
