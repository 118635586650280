interface IPositionModel {
    latitude: number;
    longitude: number;
}

class PositionModel implements IPositionModel {
    latitude: number;
    longitude: number;

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    static fromJson(json: {
        latitude: number;
        longitude: number;
    }): PositionModel {
        return new PositionModel(json.latitude, json.longitude);
    }

    static fromJsonUpperCase(json: {
        Latitude: number;
        Longitude: number;
    }): PositionModel {
        return new PositionModel(json.Latitude, json.Longitude);
    }
}

export default PositionModel;
