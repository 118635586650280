import { useNavigate } from 'react-router-dom';
import styles from './quests_page.module.css';
import arrowBackIcon from '../../assets/images/icons/arrow_back.png';
import QuestItem, { Quest } from '../components/quest_item';
import { constants } from '../../utils/constants';
import { shareURL } from '@telegram-apps/sdk';
import ValueContainer from '../../utils/value_container';

const QuestsPage = () => {
    const navigate = useNavigate();

    const quests: Quest[] = [
        {
            title: 'Join Telegram',
            description: 'Join DIGGERS GO Telegram channel',
            action: () => console.log('Join Telegram'),
        },
        {
            title: 'Follow Twitter/X',
            description: 'Follow DIGGERS GO on Twitter/X',
            action: () => console.log('Follow Twitter/X'),
        },
        {
            title: 'Refer a friend',
            description: 'Refer a friend to DIGGERS GO',
            action: () => inviteFriends(),
        },
    ];

    const customSafeArea = 60;

    const API_URL = constants.INVITE_URL;
    const inviteFriends = () => {
        const inviteLink = `${API_URL}?startapp=${ValueContainer.telegramId}`;
        if (window.Telegram !== undefined)
            shareURL(inviteLink, "Hey! Let's play RPSLegend together! 🎮");
    };

    const handleCopyLink = () => {
        const inviteLink = `${API_URL}?startapp=${ValueContainer.telegramId}`;
        navigator.clipboard.writeText(inviteLink);
        alert('Invite link copied to clipboard!');
    };

    return (
        <div className={styles.questsPage}>
            <div className={styles.appBar}>
                <div className={styles.appBarContent}>
                    <button
                        onClick={() => navigate(-1)}
                        className={styles.backButton}
                    >
                        <img src={arrowBackIcon} alt="Back" width="24" />
                    </button>
                    <h1 className={styles.appBarTitle}>Quests</h1>
                </div>
            </div>
            <div className={styles.contentArea}>
                {quests.map((quest: Quest, index) => (
                    <div key={index}>
                        {index === 0 && (
                            <div style={{ height: customSafeArea / 10 }} />
                        )}
                        <QuestItem {...quest} />
                        <div style={{ height: customSafeArea / 10 }} />
                        {index === quests.length - 1 && (
                            <div style={{ height: customSafeArea }} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestsPage;
