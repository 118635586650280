import AuthenticationComponent from '../../components/authentication/authentication_component';
import ComponentContainer from '../component_container';
import SignalRComponent from '../../components/signalr/signalr_component';
import UnityComponent from '../../components/unity/unity_component';
import SettingsComponent from '../../components/settings/settings_component';
import FriendComponent from '../../components/friend/friend_component';
import CustomizationComponent from '../../components/customization/customization_component';
import NavigationComponent from '../../components/navigation/navigation_component';
import OverlayComponent from '../../components/overlay/overlay_component';
import LocationComponent from '../../components/location/location_component';

class ContainerHelper {
    static getAuthenticationComponent(): Promise<AuthenticationComponent> {
        return ComponentContainer.instance!.getComponent(
            AuthenticationComponent
        ).then((component) => component as AuthenticationComponent);
    }

    static async getSignalRComponent(): Promise<SignalRComponent> {
        let component =
            await ComponentContainer.instance!.getComponent(SignalRComponent);
        return component as SignalRComponent;
    }

    static async getUnityComponent(): Promise<UnityComponent> {
        let component =
            await ComponentContainer.instance!.getComponent(UnityComponent);
        return component as UnityComponent;
    }

    static async getSettingsComponent(): Promise<SettingsComponent> {
        let component =
            await ComponentContainer.instance!.getComponent(SettingsComponent);
        return component as SettingsComponent;
    }

    static async getFriendComponent(): Promise<FriendComponent> {
        let component =
            await ComponentContainer.instance!.getComponent(FriendComponent);
        return component as FriendComponent;
    }

    static async getCustomizationComponent(): Promise<CustomizationComponent> {
        let component = await ComponentContainer.instance!.getComponent(
            CustomizationComponent
        );
        return component as CustomizationComponent;
    }

    static async getNavigationComponent(): Promise<NavigationComponent> {
        let component =
            await ComponentContainer.instance!.getComponent(
                NavigationComponent
            );
        return component as NavigationComponent;
    }

    static forceGetNavigationComponent(): NavigationComponent {
        return ComponentContainer.instance!.forceGet(
            NavigationComponent
        ) as NavigationComponent;
    }

    static async getOverlayComponent(): Promise<OverlayComponent> {
        const component =
            await ComponentContainer.instance!.getComponent(OverlayComponent);
        return component as OverlayComponent;
    }

    static async getLocationComponent(): Promise<LocationComponent> {
        const component =
            await ComponentContainer.instance!.getComponent(LocationComponent);
        return component as LocationComponent;
    }
}

export default ContainerHelper;
