import Color from '../../utils/color';

class MapProfile {
    ids: string[];
    logoUrls: string[];
    preferredBackgroundColors: (Color | null)[];
    names: string[];
    descriptions: string[];
    color: Color;
    latitude: number;
    longitude: number;
    range: number;
    isCluster: boolean;

    constructor(params: {
        ids: string[];
        logoUrls: string[];
        preferredBackgroundColors: (Color | null)[];
        names: string[];
        descriptions: string[];
        color: Color;
        latitude: number;
        longitude: number;
        range: number;
        isCluster: boolean;
    }) {
        this.ids = params.ids;
        this.logoUrls = params.logoUrls;
        this.preferredBackgroundColors = params.preferredBackgroundColors;
        this.names = params.names;
        this.descriptions = params.descriptions;
        this.color = params.color;
        this.latitude = params.latitude;
        this.longitude = params.longitude;
        this.range = params.range;
        this.isCluster = params.isCluster;
    }

    static fromJson(json: { [key: string]: any }): MapProfile {
        return new MapProfile({
            ids: json['Ids'] as string[],
            logoUrls: json['LogoUrls'] as string[],
            preferredBackgroundColors: (
                json['PreferredBackgroundColors'] as any[]
            ).map((e) => (e ? Color.fromJson(e) : null)),
            names: json['Names'] as string[],
            descriptions: json['Descriptions'] as string[],
            color: Color.fromJson(json['Color']),
            latitude: json['Latitude'],
            longitude: json['Longitude'],
            range:
                typeof json['Range'] === 'number'
                    ? json['Range']
                    : parseFloat(json['Range']),
            isCluster: json['IsCluster'],
        });
    }

    static fromJsonLowerCamelCase(json: { [key: string]: any }): MapProfile {
        return new MapProfile({
            ids: json['ids'] as string[],
            logoUrls: json['logoUrls'] as string[],
            preferredBackgroundColors: (
                json['preferredBackgroundColors'] as any[]
            ).map((e) => (e ? Color.fromJson(e) : null)),
            names: json['names'] as string[],
            descriptions: json['descriptions'] as string[],
            color: Color.fromJson(json['color']),
            latitude: json['latitude'],
            longitude: json['longitude'],
            range:
                typeof json['range'] === 'number'
                    ? json['range']
                    : parseFloat(json['range']),
            isCluster: json['isCluster'],
        });
    }

    isSame(other: MapProfile): boolean {
        return (
            this.ids.length === other.ids.length &&
            this.ids.every((id) => other.ids.includes(id))
        );
    }
}

export default MapProfile;
