import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration, Duration } from 'typed-duration';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import TelegramComponent from '../telegram/telegram_component';
import UnityComponent from '../unity/unity_component';
import SignalRComponent from '../signalr/signalr_component';
import ComponentContainer from '../../component_container/component_container';
import SettingsComponent from '../settings/settings_component';
import AuthenticationComponent from '../authentication/authentication_component';
import ContainerHelper from '../../component_container/utilities/container_helper';

type PositionListener = (
    previousPosition: GeolocationPosition,
    position: GeolocationPosition,
    sinceLastPositionTime: TimeDuration
    // speed: number,
    // distance: number
) => void;

const _createDebugPosition = (
    latitude: number,
    longitude: number
): GeolocationPosition => ({
    coords: {
        latitude,
        longitude,
        accuracy: 0,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
    },
    timestamp: Date.now(),
});

const _debugPositions: GeolocationPosition[] = [
    _createDebugPosition(51.348545621306926, 1.0038796844577356), // Whitstable England
    _createDebugPosition(59.6418944, 16.5661737), // Hökåsen
    // _createDebugPosition(56.14009549759487, 8.951753119026703), // Denmark
    _createDebugPosition(59.61136115226467, 16.54513267334196), // Västerås City
    _createDebugPosition(59.59997257471112, 16.53818265982465), //  Löga
    _createDebugPosition(59.60748655116136, 16.548316360000612), // Vasaparken Västerås
    _createDebugPosition(59.39845036681291, 18.0363214429389), // Mörby Centrum
    _createDebugPosition(59.369647976816026, 18.005005868318083), // MOS
    _createDebugPosition(59.33227446673747, 18.06402059942905), // Plattan
    _createDebugPosition(51.51013440703672, -0.14722883821790014), // London, Mayfair
    _createDebugPosition(51.508291649555765, -0.07594187220169456), // London, Tower Bridge
    _createDebugPosition(25.10980299980466, 55.16810470722475), // Dubai
    _createDebugPosition(58.88629819549167, 25.556509188294818), // Estonia
    _createDebugPosition(59.39021557467011, 18.035890455842775), // Stockholm
    _createDebugPosition(59.33227446673747, 18.06402059942905), // Plattan
];

class LocationComponent extends Component {
    private _useDebugPositions = false;
    private _currentDebugPosition = _debugPositions[0];

    private _lastPosition?: GeolocationPosition;
    private _lastPositionTime?: Date;

    private _watchId?: number;

    private _settingsComponent: SettingsComponent | undefined;

    get lastPosition(): GeolocationPosition | undefined {
        return this._lastPosition;
    }

    get lastPositionTime(): Date | undefined {
        return this._lastPositionTime;
    }

    private _positionListeners: PositionListener[] = [];

    addPositionListener(listener: PositionListener): void {
        this._positionListeners.push(listener);
    }

    removePositionListener(listener: PositionListener): void {
        this._positionListeners = this._positionListeners.filter(
            (l) => l !== listener
        );
    }

    private get unityComponent(): Promise<UnityComponent> {
        return this.getComponent(UnityComponent).then(
            (component) => component as UnityComponent
        );
    }

    private get signalRComponent(): Promise<SignalRComponent> {
        return this.getComponent(SignalRComponent).then(
            (component) => component as SignalRComponent
        );
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([
            AuthenticationComponent,
            SettingsComponent,
        ]);

        this._settingsComponent = await ContainerHelper.getSettingsComponent();

        if (!navigator.geolocation) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Geolocation is not supported by your browser.' // TODO: tr()
                ),
            ];
        }

        const [granted, currentPosition] =
            await this.askForLocationPermission();

        if (!granted) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Permission to use location was denied.' // TODO: tr()
                ),
            ];
        }

        try {
            this._lastPosition = !this._useDebugPositions
                ? currentPosition || (await this.getCurrentPosition())
                : this._currentDebugPosition;
            this._lastPositionTime = new Date(
                this._lastPosition.timestamp || Date.now()
            );
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Error getting current position.' // TODO: tr()
                ),
            ];
        }

        if (!this.startPositionListener()) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'Error starting position listener.' // TODO: tr()
                ),
            ];
        }

        this.unityComponent.then((unityComponent) => {
            unityComponent.addEventListener((event, args) => {
                if (event === 'interpolator:destination:reached') {
                    const objectName: string = args[0] as string;
                    if (objectName === 'Character') {
                        const latitude: number = args[1] as number;
                        const longitude: number = args[2] as number;

                        this.signalRComponent.then((signalRComponent) => {
                            const lastBoot = new Date();
                            lastBoot.setUTCMilliseconds(
                                lastBoot.getUTCMilliseconds() -
                                    performance.now()
                            );

                            console.log(
                                'Destination reached:',
                                latitude,
                                longitude,
                                lastBoot.toISOString().replace('Z', '')
                            );

                            console.log(
                                typeof latitude,
                                typeof longitude,
                                typeof lastBoot
                            );

                            signalRComponent.sendLocation(
                                latitude,
                                longitude,
                                lastBoot
                            );
                        });
                    }
                }
            });
        });

        this.addPositionListener(this._defaultPositionListener); // TODO: Remove at unload?

        ComponentContainer.instance!.makeSureLoaded.then(() => {
            this.onPositionChange(
                this._lastPosition!,
                Duration.milliseconds.of(500)
            );
        });

        return [];
    }

    private _defaultPositionListener = (
        previousPosition: GeolocationPosition,
        position: GeolocationPosition,
        sinceLastPositionTime: TimeDuration
    ) => {
        this.unityComponent.then((unityComponent) => {
            unityComponent.postInterpolationDestinationWithReachTime(
                'Character',
                position.coords.latitude,
                position.coords.longitude,
                new Date(
                    Date.now() +
                        Duration.milliseconds.from(sinceLastPositionTime)
                )
            );

            this.signalRComponent.then(async (signalRComponent) => {
                await signalRComponent.sendInterpolation(
                    previousPosition.coords.latitude,
                    previousPosition.coords.longitude,
                    position.coords.latitude,
                    position.coords.longitude,
                    Duration.milliseconds.from(sinceLastPositionTime)
                );
            });
        });
    };

    public setUseDebugPositions(useDebugPositions: boolean) {
        this._useDebugPositions = useDebugPositions;
    }

    public reportDebugPositionChange(
        position: GeolocationPosition,
        sinceLastPositionTime?: TimeDuration
    ) {
        this.onPositionChange(position, sinceLastPositionTime);
    }

    private onPositionChange(
        position: GeolocationPosition,
        sinceLastPositionTime?: TimeDuration
    ): void {
        const distance = LocationComponent.getDistanceBetweenTwoPoints(
            this._lastPosition!.coords.latitude,
            this._lastPosition!.coords.longitude,
            position.coords.latitude,
            position.coords.longitude
        );

        console.log(
            this._settingsComponent!.getDoubleFromClientSettings(
                'MinDistanceToUpdatePosition',
                100
            )
        );

        if (
            !sinceLastPositionTime &&
            distance <
                this._settingsComponent!.getDoubleFromClientSettings(
                    'MinDistanceToUpdatePosition',
                    100
                )
        ) {
            return;
        }

        const lastPosition = this._lastPosition;
        const lastPositionTime = this._lastPositionTime;

        // Update last position and time
        this._lastPosition = position;
        this._lastPositionTime = new Date(position.timestamp || Date.now());

        // Calculate time since last position update
        let sinceLastPosition =
            sinceLastPositionTime ||
            (lastPositionTime
                ? Duration.milliseconds.of(
                      this._lastPositionTime.getTime() -
                          lastPositionTime.getTime()
                  )
                : Duration.milliseconds.of(0));

        if (!sinceLastPositionTime) {
            // time was calculated
            const maxTimeBetweenPositions =
                this._settingsComponent!.getDoubleFromClientSettings(
                    'MaxTimeBetweenPositions',
                    5000
                );
            const maxTimeBetweenPositionsDuration: TimeDuration =
                Duration.milliseconds.of(maxTimeBetweenPositions * 1000);

            if (
                sinceLastPosition.value > maxTimeBetweenPositionsDuration.value
            ) {
                console.log(
                    'Time since last position was too long:',
                    sinceLastPosition
                );

                sinceLastPosition = maxTimeBetweenPositionsDuration;
            }
        }

        // TODO: Implement max time between positions
        // timePassed >
        // Duration(
        //     seconds:
        // _settingsComponent.settings!["MaxTimeBetweenPositions"]))
        // timePassed = Duration(
        //     seconds: _settingsComponent.settings!["MaxTimeBetweenPositions"]);

        if (sinceLastPosition.value < 0) {
            sinceLastPosition.value = 0;
        }

        // TODO: Implement min distance between positions
        // if (calculateDistanceWithPositions(_lastPosition, position) >
        //     _settingsComponent.settings!["MinDistanceToUpdatePosition"]) {
        //     _handleNewPosition(position);
        // }

        // Notify listeners of the position change
        this._positionListeners.forEach((listener) =>
            listener(this._lastPosition!, position, sinceLastPosition)
        );

        // Log position data
        console.log(
            'New position:',
            position,
            'Time since last position:',
            sinceLastPosition
        );
    }

    static getDistanceBetweenTwoPoints(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number
    ): number {
        const R = 6371000; // Radius of the Earth in meters

        // Helper to convert degrees to radians
        const deg2rad = (deg: number) => deg * (Math.PI / 180);

        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);

        // Haversine formula
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) *
                Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in meters
    }

    static calculatePositionFromDistanceAndBearing(
        position: GeolocationPosition,
        distance: number,
        bearing: number
    ): GeolocationPosition {
        const radius = 6371000; // Earth radius in meters
        const angularDistance = distance / radius;
        const trueCourse = toRadians(bearing);

        const latitude1 = toRadians(position.coords.latitude);
        const longitude1 = toRadians(position.coords.longitude);

        const latitude2 = Math.asin(
            Math.sin(latitude1) * Math.cos(angularDistance) +
                Math.cos(latitude1) *
                    Math.sin(angularDistance) *
                    Math.cos(trueCourse)
        );

        const longitude2 =
            longitude1 +
            Math.atan2(
                Math.sin(trueCourse) *
                    Math.sin(angularDistance) *
                    Math.cos(latitude1),
                Math.cos(angularDistance) -
                    Math.sin(latitude1) * Math.sin(latitude2)
            );

        // Helper functions for degree/radian conversion
        function toRadians(degrees: number): number {
            return degrees * (Math.PI / 180);
        }

        function toDegrees(radians: number): number {
            return radians * (180 / Math.PI);
        }

        return {
            coords: {
                latitude: toDegrees(latitude2),
                longitude: toDegrees(longitude2),
                altitude: null,
                accuracy: 0,
                altitudeAccuracy: null,
                heading: null,
                speed: null,
            },
            timestamp: Date.now(),
        };
    }

    private startPositionListener(): boolean {
        // If there's already an active watch, return a resolved promise
        if (this._watchId) {
            return true;
        }

        this._watchId = navigator.geolocation.watchPosition(
            (position) => {
                if (!this._useDebugPositions) {
                    this.onPositionChange(position);
                }
            },
            (error) => {
                console.error('Error watching position:', error.message);
            },
            {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            }
        );

        return true;
    }

    private stopPositionListener(): void {
        if (this._watchId) {
            navigator.geolocation.clearWatch(this._watchId);
            this._watchId = undefined;
        }
    }

    private getCurrentPosition(): Promise<GeolocationPosition> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => resolve(position),
                (error) => reject(error),
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: Infinity,
                }
            );
        });
    }

    private askForLocationPermission(): Promise<
        [boolean, GeolocationPosition?]
    > {
        return new Promise((resolve) => {
            if (!navigator.permissions || !navigator.permissions.query) {
                console.warn(
                    'Permissions API is not supported in this browser.'
                );
                // Fallback: attempt to get current position to prompt user directly
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve([true, position]),
                    (error) => {
                        console.error(
                            'Geolocation access denied or unavailable:',
                            error.message
                        );
                        resolve([false]);
                    }
                );
                return;
            }

            navigator.permissions
                .query({ name: 'geolocation' as PermissionName })
                .then((result) => {
                    console.log('Permission state:', result.state);

                    if (result.state === 'granted') {
                        resolve([true]);
                    } else if (result.state === 'prompt') {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                resolve([true, position]);
                            },
                            (error) => {
                                console.error(
                                    'Geolocation access denied or unavailable:',
                                    error.message
                                );
                                resolve([false]);
                            }
                        );
                    } else {
                        console.warn('Geolocation permission denied.');
                        resolve([false]);
                    }
                })
                .catch((error) => {
                    console.error(
                        'Error querying geolocation permissions:',
                        error.message
                    );
                    resolve([false]);
                });
        });
    }

    get name(): string {
        return 'Location Component';
    }

    async onPause(): Promise<void> {
        this.stopPositionListener();
    }

    async onResume(): Promise<void> {
        await this.startPositionListener();
    }

    async onUnload(): Promise<void> {
        this.stopPositionListener();
    }

    update(sinceLastUpdate: TimeDuration): void {
        const milliseconds = Duration.milliseconds.from(sinceLastUpdate);
    }

    get type(): Function {
        return LocationComponent;
    }
}

export default LocationComponent;
