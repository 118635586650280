import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';

type LevelProgressBarProps = {
    width: number;
    height: number;
    borderRadius?: number;
    backgroundColor?: string;
    progressColor?: string;
};

const LevelProgressBar = ({
    width,
    height,
    borderRadius = height / 2,
    backgroundColor = 'var(--primary-color)',
    progressColor = 'var(--gold-color)',
}: LevelProgressBarProps) => {
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );

    const experienceGained =
        (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);
    let experienceProgress = experienceGained / (currentLevelExperience ?? 1);

    experienceProgress = Math.max(0, Math.min(1, experienceProgress));

    return (
        <div
            style={{
                width,
                height,
                borderRadius,
                backgroundColor,
                display: 'flex',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    width: `${experienceProgress * 100}%`,
                    height: '100%',
                    backgroundColor: progressColor,
                }}
            ></div>
        </div>
    );
};

export default LevelProgressBar;
