import background from '../../../assets/images/backgrounds/background.png';
import blueTile from '../../../assets/images/backgrounds/blue_tile.png';
import buttonGreenSmall from '../../../assets/images/backgrounds/button_green_small.png';
import buttonOrangeBig from '../../../assets/images/backgrounds/button_orange_big.png';

import arrowBackIcon from '../../../assets/images/icons/arrow_back.png';
import closeIcon from '../../../assets/images/icons/exit_icon.png';
import exitIcon from '../../../assets/images/icons/exit.png';
import greenSmallPlus from '../../../assets/images/icons/button_green_small_plus.png';
import checkmark from '../../../assets/images/icons/checkmark.png';
import coinIcon from '../../../assets/images/icons/coin.png';
import diggersGoCoin from '../../../assets/images/icons/diggers_go_coin.png';
import locationIcon from '../../../assets/images/icons/location.png';
import star from '../../../assets/images/icons/star.png';

import coinStackIcon from '../../../assets/images/navigation_tab/coin_stack_icon.png';
import coinStacksIcon from '../../../assets/images/navigation_tab/coin_stacks_icon.png';
import hammerIcon from '../../../assets/images/navigation_tab/hammer_icon.png';
import heartIcon from '../../../assets/images/navigation_tab/heart_icon.png';
import navigationBarBackground from '../../../assets/images/navigation_tab/navigation_bar_background.png';
import notebookIcon from '../../../assets/images/navigation_tab/notebook_icon.png';
import presentIcon from '../../../assets/images/navigation_tab/present_icon.png';
import tabActive from '../../../assets/images/navigation_tab/tab_active.png';
import tabInactive from '../../../assets/images/navigation_tab/tab_inactive.png';

import blueHexagon from '../../../assets/images/panels/blue_hexagon.png';
import blueTransparentRibbon from '../../../assets/images/panels/blue_transparent_ribbon.png';
import frame from '../../../assets/images/panels/frame.png';
import ribbonVioletBig from '../../../assets/images/panels/ribbon_violet_big.png';
import topPanelCounter from '../../../assets/images/panels/top_panel_counter.png';

import logoNoBg from '../../../assets/images/logos/logo_no_bg.png';

const Images = {
    Backgrounds: {
        Background: background,
        BlueTile: blueTile,
        ButtonGreenSmall: buttonGreenSmall,
        ButtonOrangeBig: buttonOrangeBig,
    },
    Icons: {
        ArrowBack: arrowBackIcon,
        Close: closeIcon,
        Exit: exitIcon,
        GreenSmallPlus: greenSmallPlus,
        Checkmark: checkmark,
        Coin: coinIcon,
        Location: locationIcon,
        Star: star,
        DiggersGoCoin: diggersGoCoin,
    },
    NavigationTab: {
        CoinStack: coinStackIcon,
        CoinStacks: coinStacksIcon,
        Hammer: hammerIcon,
        Heart: heartIcon,
        NavigationBarBackground: navigationBarBackground,
        Notebook: notebookIcon,
        Present: presentIcon,
        TabActive: tabActive,
        TabInactive: tabInactive,
    },
    Panels: {
        BlueHexagon: blueHexagon,
        BlueTransparentRibbon: blueTransparentRibbon,
        Frame: frame,
        RibbonVioletBig: ribbonVioletBig,
        TopPanelCounter: topPanelCounter,
    },
    Logos: {
        logoNoBg: logoNoBg,
    },
};

export default Images;
