import { useEffect, useState } from 'react';
import ObservableValue from './observable_value';

function useObservable<T>(observable: ObservableValue<T>) {
    const [value, setValue] = useState<T | undefined>(observable.nullableValue);

    useEffect(() => {
        const listener = (oldValue: T | undefined, newValue: T) => {
            setValue(newValue);
        };

        observable.addListener(listener);
        return () => {
            observable.removeListener(listener);
        };
    }, [observable]);

    return value;
}

export default useObservable;
