import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { constants } from '../../../utils/constants';

const API_URL = constants.API_URL;

export type MethodHandler = (...args: any[]) => void;
type ConnectionCloseHandler = (error?: Error) => void;
type ConnectionOpenHandler = () => void;

const url = `${API_URL}CommunicationHub`;

class SignalRHelper {
    private _hubConnection: HubConnection;
    private _connectionOpenHandlers: ConnectionOpenHandler[] = [];
    private _connectionCloseHandlers: ConnectionCloseHandler[] = [];

    constructor() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(url, {
                withCredentials: false,
            })
            .configureLogging(LogLevel.Information)
            .build();

        this._hubConnection.onclose((error) => {
            this._connectionCloseHandlers.forEach((handler) => handler(error));
        });
    }

    get connected(): boolean {
        return this._hubConnection.state === HubConnectionState.Connected;
    }

    get connecting(): boolean {
        return this._hubConnection.state === HubConnectionState.Connecting;
    }

    get connectionState(): HubConnectionState {
        return this._hubConnection.state;
    }

    get connectionId(): string | null {
        return this._hubConnection.connectionId;
    }

    addConnectionOpenHandler(handler: ConnectionOpenHandler): void {
        this._connectionOpenHandlers.push(handler);
    }

    removeConnectionOpenHandler(handler: ConnectionOpenHandler): void {
        this._connectionOpenHandlers = this._connectionOpenHandlers.filter(
            (h) => h !== handler
        );
    }

    addConnectionCloseHandler(handler: ConnectionCloseHandler): void {
        this._connectionCloseHandlers.push(handler);
    }

    removeConnectionCloseHandler(handler: ConnectionCloseHandler): void {
        this._connectionCloseHandlers = this._connectionCloseHandlers.filter(
            (h) => h !== handler
        );
    }

    async connect(): Promise<void> {
        try {
            await this._hubConnection.start();
            this._connectionOpenHandlers.forEach((handler) => handler());
        } catch (error) {
            console.error('SignalR connection failed: ', error);
            throw error;
        }
    }

    registerMethodHandler(methodName: string, handler: MethodHandler): void {
        this._hubConnection.on(methodName, handler);
    }

    unregisterMethodHandler(methodName: string, handler: MethodHandler): void {
        this._hubConnection.off(methodName, handler);
    }

    async invoke<T = any>(methodName: string, ...args: any[]): Promise<T> {
        try {
            return await this._hubConnection.invoke<T>(methodName, ...args);
        } catch (error) {
            console.error(
                `Error invoking method ${methodName} with arguments`,
                args,
                error
            );
            throw error;
        }
    }

    async disconnect(): Promise<void> {
        try {
            await this._hubConnection.stop();
        } catch (error) {
            console.error('Error disconnecting SignalR: ', error);
        }
    }
}

export default SignalRHelper;
