class PlayabilityModel {
    private readonly _verdict: number;
    private readonly _reason: number;
    private readonly _value: number;

    constructor(verdict: number, reason: number, value: number) {
        this._verdict = verdict;
        this._reason = reason;
        this._value = value;
    }

    static fromJsonLowerCamelCase(json: any): PlayabilityModel {
        return new PlayabilityModel(json.verdict, json.reason, json.value);
    }

    static fromJson(json: any): PlayabilityModel {
        return new PlayabilityModel(json.Verdict, json.Reason, json.Value);
    }

    get verdict(): string {
        switch (this._verdict) {
            case 0:
                return 'PLAYABLE';
            case 1:
                return 'UNPLAYABLE';
            default:
                return 'UNKNOWN';
        }
    }

    get reason(): string {
        switch (this._reason) {
            case 0:
                return 'SPEED';
            case 1:
                return 'DISTANCE';
            case 2:
                return 'TIME';
            case 3:
                return 'NOT_CHECKED';
            default:
                return 'UNKNOWN';
        }
    }

    get value(): number {
        return this._value;
    }
}

export default PlayabilityModel;
