import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './custom_bottom_navigation_bar.module.css';

import hammerIcon from '../../assets/images/navigation_tab/hammer_icon.png';
import coinStackIcon from '../../assets/images/navigation_tab/coin_stack_icon.png';
import heartIcon from '../../assets/images/navigation_tab/heart_icon.png';
import notebookIcon from '../../assets/images/navigation_tab/notebook_icon.png';

import navigationBarBackground from '../../assets/images/navigation_tab/navigation_bar_background.png';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import NavigationComponent from '../../component_system/components/navigation/navigation_component';

const CustomBottomNavigationBar: React.FC<any> = () => {
    const [height, setHeight] = useState(0);
    const prevHeightRef = useRef(0);
    const ref = useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const [totalNotifications, setTotalNotifications] = useState(0);

    const [navigationComponent, setNavigationComponent] = useState<
        NavigationComponent | undefined
    >(undefined);

    useEffect(() => {
        if (ref.current) {
            const currentHeight = ref.current.clientHeight;

            // Only update height if it has changed
            if (currentHeight !== prevHeightRef.current) {
                setHeight(currentHeight);
                prevHeightRef.current = currentHeight; // Update the previous height
            }
        }
    });

    useEffect(() => {
        if (height > 0) {
            const navComponent = ContainerHelper.forceGetNavigationComponent();
            navComponent.navigationBarHeight = height;
        }
    }, [height]);

    useEffect(() => {
        ContainerHelper.getNavigationComponent().then((component) => {
            setNavigationComponent(component);
        });

        return () => {
            // Clean up subscriptions on unmount
        };
    }, []);

    const navigationComponentSubscriber = useCallback(() => {
        if (navigationComponent) {
            setSelectedIndex(navigationComponent.selectedIndex);
            setVisible(navigationComponent.visible);
        }
    }, [navigationComponent]);

    useEffect(() => {
        if (navigationComponent) {
            navigationComponent.addSubscriber(navigationComponentSubscriber);
        }

        return () => {
            if (navigationComponent) {
                navigationComponent.removeSubscriber(
                    navigationComponentSubscriber
                );
            }
        };
    }, [navigationComponent]);

    const handleNavigateToIndex = useCallback(
        (index: number) => {
            if (navigationComponent) {
                navigationComponent.navigateToIndex(index);
            }
        },
        [navigationComponent]
    );

    const _getIconTheme = (index: number) => {
        return index === selectedIndex
            ? { height: '32px', color: '#FFFFFF' }
            : { height: '26px', color: '#CCCCCC' };
    };

    return (
        <div
            className={styles.bottomNavBar}
            style={!visible ? { zIndex: -1 } : {}}
        >
            <div className={styles.gradientOverlay} />
            <div
                className={styles.backgroundImage}
                style={{
                    backgroundImage: `url(${navigationBarBackground})`,
                }}
                ref={ref}
            >
                <div className={styles.navItems}>
                    {/* Icon 1: Play */}
                    <NavItem
                        index={0}
                        iconImage={hammerIcon}
                        label="Play"
                        isSelected={selectedIndex === 0}
                        onNavigate={() => handleNavigateToIndex(0)}
                        iconStyle={_getIconTheme(0)}
                    />

                    {/* Icon 2: Rewards */}
                    <NavItem
                        index={1}
                        iconImage={coinStackIcon}
                        label="Rewards"
                        isSelected={selectedIndex === 1}
                        onNavigate={() => handleNavigateToIndex(1)}
                        iconStyle={_getIconTheme(1)}
                        badgeCount={totalNotifications /* replace as needed */}
                    />

                    {/* Icon 3: Friends */}
                    <NavItem
                        index={2}
                        iconImage={heartIcon}
                        label="Friends"
                        isSelected={selectedIndex === 2}
                        onNavigate={() => handleNavigateToIndex(2)}
                        iconStyle={_getIconTheme(2)}
                    />

                    {/* Icon 4: Quests */}
                    <NavItem
                        index={3}
                        iconImage={notebookIcon}
                        label="Quests"
                        isSelected={selectedIndex === 3}
                        onNavigate={() => handleNavigateToIndex(3)}
                        iconStyle={_getIconTheme(3)}
                    />
                </div>
            </div>
        </div>
    );
};

interface NavItemProps {
    index: number;
    iconImage: string;
    label: string;
    isSelected: boolean;
    onNavigate: () => void;
    iconStyle: React.CSSProperties;
    badgeCount?: number;
}

const NavItem: React.FC<NavItemProps> = ({
    iconImage,
    label,
    isSelected,
    onNavigate,
    iconStyle,
    badgeCount = 0,
}) => (
    <div className={styles.navItem} onClick={onNavigate}>
        <div className={styles.iconWrapper}>
            <img src={iconImage} alt={label} style={iconStyle} />
            {badgeCount > 0 && (
                <span className={styles.badge}>{badgeCount}</span>
            )}
        </div>
        <span className={isSelected ? styles.selectedLabel : styles.label}>
            {label}
        </span>
    </div>
);

export default CustomBottomNavigationBar;
