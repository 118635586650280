import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import ElementData from './element_data';
import ControlledPositionElement from '../../../ui/components/overlay/controlled_position_element';
import UnityComponent from '../unity/unity_component';
import React, { ReactNode } from 'react';
import { CurrencyType } from '../../../ui/components/currency_icon';
import AmountGainedFadeOut from '../../../ui/components/overlay/amount_gained_fade_out';
import ValueContainer from '../../../utils/value_container';
import ComponentErrorType from '../../component_container/enums/component_error_type';

type OverlayComponentSubscriber = (elements: React.JSX.Element[]) => void;

class OverlayComponent extends Component {
    private _unityComponent: UnityComponent | undefined;

    private _subscribers: OverlayComponentSubscriber[] = [];

    addSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._elements = this._mapElements();
        this._subscribers.forEach((subscriber) => subscriber(this._elements));
    }

    private _elementData: ElementData[] = [];
    private _elements: React.JSX.Element[] = [];

    get elements(): React.JSX.Element[] {
        return this._elements;
    }

    async addElement(
        gameObject: string,
        children: ReactNode,
        lifetime: number | undefined
    ): Promise<void> {
        const id = Math.random().toString(36).substring(7);
        const ref = React.createRef<{
            setPosition: (x: number, y: number) => void;
            triggerFadeOut: () => void;
        }>();

        const screenPosition = await this._getScreenPosition(
            gameObject,
            'top',
            0
        );

        const element = new ElementData(
            id,
            children,
            ref,
            Date.now(),
            lifetime,
            gameObject,
            { x: screenPosition[0], y: screenPosition[1] }
        );
        this._elementData.push(element);
        this._notifySubscribers();
    }

    private _mapElements() {
        return this._elementData.map((element) => (
            <ControlledPositionElement
                key={element.id}
                ref={element.ref}
                children={element.children}
                initialPosition={element.initialPosition}
            />
        ));
    }

    private _coinsObservableListener(
        oldValue: number | undefined,
        newValue: number
    ) {
        if (oldValue === undefined) {
            return;
        }
        const amount = newValue - oldValue;
        if (amount > 0) {
            this.addElement(
                'Character',
                <AmountGainedFadeOut
                    amount={amount}
                    currencyType={CurrencyType.Coin}
                />,
                2000
            );
        }
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([UnityComponent]);
        this._unityComponent = await this.getComponent(UnityComponent).then(
            (component) => component as UnityComponent
        );

        ValueContainer.coinsObservable.addListener(
            this._coinsObservableListener.bind(this)
        );

        return [];
    }

    private async _getScreenPosition(
        gameObject: string,
        position: string,
        offset: number
    ): Promise<[number, number]> {
        if (!this._unityComponent) {
            throw new Error('Unity component not loaded');
        }

        let screenPos = await this._unityComponent.getObjectScreenPosition(
            gameObject,
            position,
            offset
        );

        screenPos[0] /= window.devicePixelRatio;
        screenPos[1] /= window.devicePixelRatio;

        return screenPos;
    }

    get name(): string {
        return 'Overlay Component';
    }

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    async onUnload(): Promise<void> {
        ValueContainer.coinsObservable.removeListener(
            this._coinsObservableListener.bind(this)
        );
    }

    get type(): Function {
        return OverlayComponent;
    }

    update(sinceLastUpdate: TimeDuration): void {
        this._elementData.forEach((element) => {
            if (element.gameObject) {
                // check if lifetime is set and if it has expired
                // use date.now - created date
                // if expired, trigger fade out and remove from array
                if (
                    element.lifetime &&
                    Date.now() - element.created > element.lifetime
                ) {
                    if (element.ref.current) {
                        element.ref.current.triggerFadeOut();
                    }

                    // check if more than 5000ms has passed since lifetime expired
                    // if so, remove element from array
                    if (
                        Date.now() - element.created >
                        element.lifetime + 5000
                    ) {
                        this._elementData = this._elementData.filter(
                            (e) => e.id !== element.id
                        );
                        this._notifySubscribers();
                    }
                    return;
                }

                this._getScreenPosition(element.gameObject, 'top', 0).then(
                    (position) => {
                        if (element.ref.current) {
                            element.ref.current.setPosition(
                                position[0],
                                position[1]
                            );
                        }
                    }
                );
            }
        });
    }
}

export default OverlayComponent;
