// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_panel_counter_currency_text__RBL7F {
    font-size: 16px;
    color: white;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/ui/components/overlay/top_panel_counter.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,2CAA2C;AAC/C","sourcesContent":[".currency_text {\r\n    font-size: 16px;\r\n    color: white;\r\n    font-weight: 600;\r\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currency_text": `top_panel_counter_currency_text__RBL7F`
};
export default ___CSS_LOADER_EXPORT___;
