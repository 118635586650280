import React from 'react';
import CurrencyIcon, { CurrencyType } from '../currency_icon';

interface AmountGainedFadeOutProps {
    amount: number;
    currencyType: CurrencyType;
    size?: number; // Size of the icon
    color?: string; // Text and icon color
    backgroundColor?: string; // Background color
    fontSize?: number; // Font size of the amount
}

const AmountGainedFadeOut: React.FC<AmountGainedFadeOutProps> = ({
    amount,
    currencyType,
    size = 14,
    color = 'var(--gold-color)',
    backgroundColor = 'rgba(var(--primary-color-triplet), 1)',
    fontSize = 12,
}) => {
    return (
        <div
            style={{
                color: color,
                padding: '4px',
                borderRadius: '5px',
                backgroundColor: backgroundColor,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <p
                style={{
                    margin: '0',
                    fontSize: `${fontSize}px`,
                    fontWeight: 'bold',
                    marginRight: '5px',
                }}
            >
                +{amount}
            </p>
            <CurrencyIcon type={currencyType} size={size} />
        </div>
    );
};

export default AmountGainedFadeOut;
